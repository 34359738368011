import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { useAppDispatch } from '../../../../../hooks/global';
import { fetchUploadFile } from '../../../../../store/uploadFile/uploadFile';
import Loader from '../../../../common/Loader/Loader';
import { Button } from '../../../../controls';
import './EditCustomer.scss';
import { states } from '../../../../../constants/main';
import UserModalFA from './parts/CreateOrEditUserModal';
import GeneralCustomer from './parts/General';
import {
  createCustomer,
  deleteCustomer,
  getCustomerInfo,
  updateCustomer,
} from '../../../../../store/customer/customer';
import { getStringWithoutSymbols } from '../../../../../helpers';
import DeleteModalConfirmation from '../../../../controls/DeleteModalConfirmation/DeleteModalConfirmation';

const EditCustomer: FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const ownerId = localStorage.getItem('owner_id');

  const [generalOpened, setGeneralOpened] = useState(true);
  const [detailsOpened, setDetailsOpened] = useState(true);
  const [generalInfo, setGeneralInfo] = useState<any>(null);
  const [isHazmat, setIsHazmat] = useState(1);
  const [isAnyFileUploded, setIsAnyFileUploded] = useState(false);
  const [addressState, setAddressState] = useState(null);
  const [billingAddressState, setBillingAddressState] = useState(null);
  const [contactPersonsOpened, setContactPersonsOpened] = useState(false);
  const [editUserPrevData, setEditUserPrevData] = useState(null);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [gender, setGender] = useState(null);
  const [mailingAddressState, setMailingAddressState] = useState(null);
  const [openDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [birth, setBirth] = useState(null);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });

  const [addressComponents, setAddressComponents] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [zip, setZip] = useState<any>(null);
  const [state_, setState] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);

  const [addressMailingComponents, setAddressMailingComponents] = useState<any>(null);
  const [cityMailing, setCityMailing] = useState<any>(null);
  const [zipMailing, setZipMailing] = useState<any>(null);
  const [stateMailing, setStateMailing] = useState<any>(null);
  const [addressMailing, setAddressMailing] = useState<any>(null);

  useEffect(() => {
    if (addressComponents) {
      let streetNumber = '';
      let route = '';
      setAddress(addressComponents.address);

      addressComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZip(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setState(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCity(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressComponents]);

  useEffect(() => {
    if (addressMailingComponents) {
      let streetNumber = '';
      let route = '';
      setAddressMailing(addressMailingComponents.address);

      addressMailingComponents.address_components.map((component: any) => {
        component.types.map((type: any) => {
          if (type === 'postal_code') {
            setZipMailing(component.long_name);
          } else if (type === 'administrative_area_level_1') {
            setStateMailing(component.short_name);
          } else if (type === 'sublocality_level_1' || type === 'locality') {
            setCityMailing(component.long_name);
          } else if (type === 'street_number') {
            streetNumber = component.long_name;
          } else if (type === 'route') {
            route = component.long_name;
          }
        });
      });
    }
  }, [addressMailingComponents]);

  useEffect(() => {
    if (generalInfo) {
      setCity(generalInfo.address.city);
      setZip(generalInfo.address.zip);
      setState(generalInfo.address.state?.code)
      setCityMailing(generalInfo.billing_address.city);
      setZipMailing(generalInfo.billing_address.zip);
      setStateMailing(generalInfo.billing_address.state?.code);
      setAddress(generalInfo.address.full_address);
      setAddressMailing(generalInfo.billing_address.full_address);
    }
  }, [generalInfo])

  useEffect(() => {
    if (state) {
      if (state.id) {
        dispatch(
          getCustomerInfo({
            type: 'fa',
            ownerId,
            customerId: +state.id,
          }),
        ).then(res => {
          if (res.type === 'getCustomer/fulfilled') {
            setGeneralInfo(res.payload.resource);
          }
        });
      }
    }
  }, [state]);

  const onSubmit = async (data: any) => {
    const id = localStorage.getItem('owner_id');
    const userData = localStorage.getItem('user');

    if (id && userData) {
      setSubmitLoading(true);
      const parsedData = JSON.parse(userData);

      const dataForUpdate: any = {
        id,
        ownerType:
          parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
            ? 'tc'
            : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
            ? 'fa'
            : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
            ? 'id'
            : null,
        customerId: generalInfo.id,
        first_name: data.first_name || generalInfo.first_name,
        last_name: data.last_name || generalInfo.last_name,
        address: {
          state: addressState,
          address: addressComponents?.shortAddress || data.address_line || generalInfo.address.address,
          full_address: addressComponents?.address || data.address_line || generalInfo.address.full_address,
          address2: data.address_line_second || generalInfo.address.address2,
          city: city || data.city || generalInfo.address.city,
          zip: zip || data.zip || generalInfo.address.zip,
          phone: getStringWithoutSymbols(data.phone) || generalInfo.address.phone,
          email: data.email || generalInfo.address.email,
          fax: getStringWithoutSymbols(data.fax) || generalInfo.address.fax,
        },
        billingAddress: {
          state: billingAddressState,
          address: addressMailingComponents?.shortAddress || data.billing_address || generalInfo.billing_address.address,
          full_address: addressMailingComponents?.address || data.billing_address || generalInfo.billing_address.full_address,
          address2: data.billing_address_second || generalInfo.billing_address.address2,
          city: cityMailing || data.billing_city || generalInfo.billing_address.city,
          zip: zipMailing || data.billing_zip || generalInfo.billing_address.zip,
          phone:
            getStringWithoutSymbols(data.billing_phone) ||
            generalInfo.billing_address.phone,
          email: data.billing_email || generalInfo.billing_address.email,
        },
      };

      if (avatar.temp) {
        dataForUpdate.avatar = {
          file: avatar.temp,
          delete: 0,
        };
      }

      dispatch(updateCustomer(dataForUpdate)).then(res => {
        if (res.type === 'updateCustomer/fulfilled') {
          navigate('/users-management', {
            state: {
              tab: 'customers',
            },
          });
        } else {
          setSubmitLoading(false);
        }
      });
    }
  };

  
  const userData = localStorage.getItem('user');
  const deleteCustomerGlobal = () => {
    if (userData) {
      const parsedData = JSON.parse(userData);
      setSubmitLoading(true);
      const id = localStorage.getItem('owner_id');
      
      dispatch(deleteCustomer({
        id,
        customerId: state.id,
        type:
          parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
            ? 'tc'
            : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
            ? 'fa'
            : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
            ? 'id'
            : null,
      })).then(res => {
        if (res.type === 'deleteCustomer/fulfilled') {
         return navigate('/users-management', {
            state: {
              tab: 'customers',
            },
          })
        } else {
          setSubmitLoading(false);
        }
      })
    }
  };

  return (
    <>
      <Loader loading={false} />

      <div className="createDriver mainContainer">
        <div className="createDriver__container">
          <div className="createDriver__body">
            <div className="createDriver__userForm">
              <div className="createDriver__header">
                <div className="createDriver__title">
                  <h1>Edit Customer</h1>
                </div>

                <Button
                  title="BACK"
                  Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                  onClick={() =>
                    navigate('/users-management', {
                      state: {
                        tab: 'customers',
                      },
                    })
                  }
                  className="mapBtn"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">General Information</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setGeneralOpened(!generalOpened)}
                  className={`openBtn ${!generalOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <GeneralCustomer
                generalOpened={generalOpened}
                control={control}
                generalInfo={generalInfo}
                states={states}
                setAddressState={setAddressState}
                setBillingAddressState={setBillingAddressState}
                setIsHazmat={setIsHazmat}
                isHazmat={isHazmat}
                setAddressComponents={setAddressComponents}
                zip={zip}
                state={state_}
                city={city}
                setAddressMailingComponents={setAddressMailingComponents}
                zipMailing={zipMailing}
                stateMailing={stateMailing}
                cityMailing={cityMailing}
                address={address}
                mailingAddress={addressMailing}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <Button
              title="SAVE ALL"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              className="saveBtn"
              loading={submitLoading}
            />
           {state?.id && <Button
              title="delete"
              onClick={() => setDeleteModal(true)}
              variant="contained"
              className="cancelBtn deleteUserBtn"
              loading={submitLoading}
            /> }
          </div>
          <DeleteModalConfirmation 
            open={openDeleteModal}
            setOpen={setDeleteModal}
            deleteFunction={deleteCustomerGlobal}
            loading={submitLoading}
            />

          <div className="createDriver__userInfo">
            <div className="addNewPhotoBtn">
              <IconButton color="primary" aria-label="upload picture" component="label">
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={e => {
                    if (e && e.target && e.target.files) {
                      const fd = new FormData();
                      fd.append('file', e.target.files[0]);
                      dispatch(fetchUploadFile(fd)).then(res => {
                        if (res.type === 'uploadFile/fulfilled') {
                          setAvatar({
                            file: res.payload.file.fullpath,
                            temp: res.payload.file.filepath,
                          });
                        }
                      });
                    }
                  }}
                />
                <CameraAltOutlinedIcon />
              </IconButton>
            </div>
            <div className="userInfo__avatar">
              <img src={avatar.file} alt="" />
            </div>
            <div className="userInfo__role">Customer</div>
          </div>

          {
            <>
              {openCreateUserModal && (
                <UserModalFA
                  open={openCreateUserModal}
                  setOpen={setOpenCreateUserModal}
                  // onChange={onChangeContactPerson}
                  // onSubmit={onAddNewUser}
                  onChange={() => null}
                  onSubmit={() => null}
                  confirmTitle="ADD"
                  cancelTitle="CANCEL"
                  contentTitle="Add New Contact Person"
                  formType="create"
                  loading={submitLoading}
                />
              )}

              {openEditUserModal && (
                <UserModalFA
                  open={openEditUserModal}
                  setOpen={setOpenEditUserModal}
                  // onChange={onChangeContactPerson}
                  // onSubmit={onEditUserInfo}
                  onChange={() => null}
                  onSubmit={() => null}
                  confirmTitle="EDIT"
                  cancelTitle="CANCEL"
                  contentTitle="Edit Contact Person"
                  defaultData={editUserPrevData}
                  setDefaultData={setEditUserPrevData}
                  formType="edit"
                  loading={submitLoading}
                />
              )}
            </>
          }
        </div>
      </div>
    </>
  );
};

export default EditCustomer;
