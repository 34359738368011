import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate, useLocation, useNavigate } from "react-router";
import { ROUTES } from "./routes";
import {
  SignIn,
  SignUp,
  ResetPassword,
  Home,
  Dashboard,
  SetPassword,
  MyProfile,
  EmailVerify,
  UserManagement,
  CreateDriver,
  EditDriver,
  CreateFA,
  CreateID,
  CreateTC,
  CreateCustomer,
  EditTC,
  EditFA,
  LoadsList,
  FullMap,
  EditID,
  EditCustomer,
  ReportsList,
  ReportAccident,
  TicketIncident,
  CreateLoad,
  EditLoad,
  Tracking,
  ReportDamage,
  Invoicing,
} from "../components/pages";
import { ThemeProvider } from "@mui/material";
import { theme } from "../assets/styles/themes";
import NotFoundPage from "../components/common/NotFoundPage/NotFoundPage";
import Header from "../components/common/Header/Header";
import { useAppDispatch, useAppSelector } from "../hooks/global";
import { getTruckCompanyInfo } from "../store/truckCompany/truckCompany";
import { getIndependentDispatcherInfo } from "../store/independentDispatcher/independentDispatcher";
import { getFreightAgentInfo } from "../store/freightAgent/freightAgent";

const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = localStorage.getItem("token");
  const userData = localStorage.getItem("user");
  const id = localStorage.getItem("owner_id");
  const dispatch = useAppDispatch();

  const tcSelector = useAppSelector((state) => state.truckCompany);
  const idSelector = useAppSelector((state) => state.independentDispatcher);
  const faSelector = useAppSelector((state) => state.freightAgent);

  const [email, setEmail] = useState<string>("Username");
  const [avatar, setAvatar] = useState<any>(null);
  const [blockAccess, setBlockAccess] = useState<boolean>(false);
  const [partialBlockAccess, setPartialBlockAccess] = useState<boolean>(false);
  const [loginAsLink, setLoginAsLink] = useState<any>(null);

  useEffect(() => {
    if (
      location.pathname === "/restore-password/" ||
      location.pathname === "/set-password/"
    ) {
      const searchArr = location.search.split("=");
      if (searchArr.length && searchArr[1]?.length) {
        localStorage.setItem("recovery_token", searchArr[1]);
      }
      navigate("/restore-password");
    }

    if (location.pathname === "/user-verify/") {
      const searchArr = location.search.split("=");
      if (searchArr.length && searchArr[1]?.length) {
        localStorage.setItem("verify_token", searchArr[1]);
      }
      navigate("/email-verify");
    }
  }, []);

  useEffect(() => {
    if (tcSelector && tcSelector.logo) {
      setAvatar(tcSelector.logo);
    }
  }, [tcSelector]);

  useEffect(() => {
    if (idSelector && idSelector.logo) {
      setAvatar(idSelector.logo);
    }
  }, [idSelector]);

  useEffect(() => {
    if (faSelector && faSelector.logo) {
      setAvatar(faSelector.logo);
    }
  }, [faSelector]);

  useEffect(() => {
    if (userData && id) {
      const parsedData = JSON.parse(userData);
      if (parsedData) {
        if (parsedData.role === "tc-admin" || parsedData.role === "tc-user") {
          dispatch(getTruckCompanyInfo(+id));
        } else if (
          parsedData.role === "id-admin" ||
          parsedData.role === "id-user"
        ) {
          dispatch(getIndependentDispatcherInfo(+id));
        } else if (
          parsedData.role === "fa-admin" ||
          parsedData.role === "fa-user"
        ) {
          dispatch(getFreightAgentInfo(+id));
        }
        setEmail(parsedData.company_name);

        if (parsedData.creator_id) {
          setBlockAccess(true);
          setPartialBlockAccess(true);
        } else {
          if (parsedData.role.includes("user")) {
            setBlockAccess(false);
            setPartialBlockAccess(true);
          } else {
            setPartialBlockAccess(false);
            setBlockAccess(false);
          }
        }
      }
    }
  }, [userData]);

  return (
    <ThemeProvider theme={theme}>
      {
        loginAsLink &&  
        <div>
          <div className="loginAsHeader" onClick={() => {
            localStorage.removeItem('isSeenBlockAccess');
            setLoginAsLink(null)
          }}>
            Exit user access and back to admin panel
          </div>
          <div style={{position: "relative"}}>
          <iframe
            src={loginAsLink}
            title=""
            style={{ width: '100%', height: '100vh', marginTop: 50, zIndex: 2 }}
            id="loginAs__iframe"
          />
          </div>
        </div>
      }

    {!loginAsLink && <>
      {isAuth && (
        <Header email={email} avatar={avatar} blockAccess={blockAccess} />
      )}

      <Routes>
        <Route
          path={ROUTES.HOME}
          element={<Navigate to={ROUTES.SIGN_IN} replace />}
        />
        <Route path={ROUTES.TRACKING} element={<Tracking isAuth={isAuth} />} />
        <Route
          path={ROUTES.SIGN_IN}
          element={
            <NotProtectedRoute>
              <SignIn />
            </NotProtectedRoute>
          }
        />
        <Route
          path={ROUTES.SIGN_UP}
          element={
            <NotProtectedRoute>
              <SignUp />
            </NotProtectedRoute>
          }
        />
        <Route
          path={ROUTES.RECOVERY_PASSWORD}
          element={
            <NotProtectedRoute>
              <ResetPassword />
            </NotProtectedRoute>
          }
        />
        <Route
          path={ROUTES.RESTORE_PASSWORD}
          element={
            <NotProtectedRoute>
              <SetPassword />
            </NotProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EMAIL_VERIFY}
          element={
            <NotProtectedRoute>
              <EmailVerify />
            </NotProtectedRoute>
          }
        />

        {/* <Route
          path={ROUTES.DASHBOARD}
          element={
            <ProtectedRoute>
              <Dashboard blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        /> */}

        <Route
          path={ROUTES.INVOICING}
          element={
            <ProtectedRoute>
              <Invoicing blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />

        <Route path={ROUTES.LOADS_MAP} element={<FullMap />} />

        <Route
          path={ROUTES.MY_PROFILE}
          element={
            <ProtectedRoute>
              {
                <MyProfile
                  tcSelector={tcSelector}
                  idSelector={idSelector}
                  faSelector={faSelector}
                />
              }
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.USER_MANAGEMENT}
          element={
            <ProtectedRoute blockAccess={blockAccess}>
              <UserManagement partialBlockAccess={partialBlockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.REPORTS_LIST}
          element={
            <ProtectedRoute blockAccess={blockAccess}>
              <ReportsList partialBlockAccess={partialBlockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.REPORT_ACCIDENT}
          element={
            <ProtectedRoute blockAccess={blockAccess}>
              <ReportAccident />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.REPORT_DAMAGE}
          element={
            <ProtectedRoute blockAccess={blockAccess}>
              <ReportDamage />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.TICKET_INCIDENT}
          element={
            <ProtectedRoute blockAccess={blockAccess}>
              <TicketIncident partialBlockAccess={partialBlockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.LOADS}
          element={
            <ProtectedRoute>
              <LoadsList blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_DRIVER}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateDriver />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_DRIVER}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditDriver />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_FA}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateFA />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_ID}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateID />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_TC}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateTC />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.ADD_NEW_CUSTOMER}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <CreateCustomer />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_TC}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditTC setLoginAsLink={setLoginAsLink}/>
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_CUSTOMER}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditCustomer />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_FA}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditFA setLoginAsLink={setLoginAsLink}/>
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.EDIT_ID}
          element={
            <ProtectedRoute
              blockAccess={blockAccess}
              partialBlockAccess={partialBlockAccess}
            >
              <EditID setLoginAsLink={setLoginAsLink}/>
            </ProtectedRoute>
          }
        />

        {/* <Route
          path={ROUTES.ADD_NEW_LOAD}
          element={
            <ProtectedRoute>
              <CreateLoad blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />*/}

        <Route
          path={ROUTES.EDIT_LOAD}
          element={
            <ProtectedRoute>
              <EditLoad blockAccess={blockAccess} />
            </ProtectedRoute>
          }
        />

        {location && location.pathname !== "/user-verify/" && (
          <Route
            path="*"
            element={
              <NotFoundPage
                title={isAuth ? "GO TO PROFILES PAGE" : "GO TO SIGN IN"}
              />
            }
          />
        )}
      </Routes>
      </>}
    </ThemeProvider>
  );
};

interface IPropTypes {
  children: any;
  blockAccess?: boolean;
  partialBlockAccess?: boolean;
}

const ProtectedRoute: React.FC<IPropTypes> = ({
  children,
  blockAccess,
  partialBlockAccess,
}) => {
  const isAuth = localStorage.getItem("token");

  if (!isAuth) {
    return <Navigate to="/sign-in" replace />;
  }

  if (blockAccess === true) {
    return <Navigate to="/dashboard" replace />;
  }

  if (partialBlockAccess === true) {
    return <Navigate to="/users-management" replace />;
  }

  return children;
};

const NotProtectedRoute: React.FC<IPropTypes> = ({ children }) => {
  const isAuth = localStorage.getItem("token");

  if (isAuth) {
    return <Navigate to="/users-management" replace />;
  }

  return children;
};

export default Router;
