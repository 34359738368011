import React, { FC, useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import Loader from '../../../common/Loader/Loader';
import {
  Button,
  Checkbox,
  ContainedInput,
  Selection,
  UploadButton,
} from '../../../controls';
import { IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './EditLoad.scss';
import Shipper from '../parts/Shipper';
import {
  equimpmentTypes,
  shipper_types,
  statuses,
  types,
} from '../../../../constants/main';
import Receiver from '../parts/Receiver';
import AutocompleteComponent from '../../../controls/AutocompleteSelection/Autocomplete';
import { useAppDispatch } from '../../../../hooks/global';
import { getCustomersList } from '../../../../store/customer/customer';
import { getIndependentDispatcherList } from '../../../../store/independentDispatcher/independentDispatcher';
import CreateCustomerModal from './parts/CreateCustomerModal';
import AsyncAutocompleteComponent from '../../../controls/AsyncAutocomplete.tsx/Autocomplete';
import {
  createLoad,
  editLoad,
  getEquipmentTypes,
  getTrailersList,
  getTrucksList,
  searchFLorTitle,
} from '../../../../store/load/load';
import { getDriversList } from '../../../../store/driver/driver';
import CreateDriverModal from './parts/CreateDriverModal';
import CreateDispatcherModal from './parts/CreateDispatcherModal';
import GoogleAutocompleteComponent from '../../../controls/GoogleAutocomplete/Autocomplete';
import CreateTruckModal from './parts/CreateTruckModal';
import CreateTrailerModal from './parts/CreateTrailerModal copy';
import { getSpecificLoad } from '../../../../store/specificLoad/specificLoad';
import { getEntityType, getFullName } from '../../../../helpers';
import CreateEquipmentTypeModal from '../CreateLoad/parts/CreateEquipmentTypeModal';
import { ButtonComponent } from '../../../controls/Button/Button';
import CreateGuestDriverModal from '../CreateLoad/parts/CreateGuestDriverModal';

interface IEditLoad {
  blockAccess?: boolean;
}

const EditLoad: FC<IEditLoad> = ({ blockAccess }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { state }: any = useLocation();
  const { handleSubmit, control } = useForm();
  const id = localStorage.getItem('owner_id');
  const entityId = localStorage.getItem('entity_id');
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  let type_: 'tc' | 'fa' | 'id' | null = null;

  if (parsedData) {
    type_ = getEntityType(parsedData.role);
  }

  const [submitLoading, setSubmitLoading] = useState(false);
  const [birth, setBirth] = useState(null);
  const [value, setValue] = useState<any>(null);
  const [shippersCount, setShippersCount] = useState<number>(1);
  const [shippers, setShippers] = useState<any>([1]);
  const [selectedShipper, setSelectedShipper] = useState<number | string>(1);
  const [selectedShippers, setSelectedShippers] = useState<any>([]);
  const [selectedReceivers, setSelectedReceivers] = useState<any>([]);
  const [receiversCount, setReceiversCount] = useState<number>(1);
  const [receivers, setReceivers] = useState<any>([1]);
  const [selectedReceiver, setSelectedReceiver] = useState<number | string>(1);
  const [billToList, setBillToList] = useState<any>([]);
  const [independentDispatchersList, setIndependentDispatchersList] = useState<any>([]);
  const [customersList, setCustomersList] = useState<any>([]);
  const [driversList, setDriversList] = useState<any>([]);
  const [trucksList, setTrucksList] = useState<any>([]);
  const [trailersList, setTrailersList] = useState<any>([]);
  const [createBillToModal, setCreateBillToModal] = useState<boolean>(false);
  const [createShipperModal, setCreateShipperModal] = useState<any>({
    open: false,
    customer_type: null,
    customer_id: null,
  });
  const [createGuestDriverModal, setCreateGuestDriverModal] = useState<boolean>(false);
  const [createDriverModal, setCreateDriverModal] = useState<boolean>(false);
  const [createTruckModal, setCreateTruckModal] = useState<boolean>(false);
  const [createTrailerModal, setCreateTrailerModal] = useState<boolean>(false);
  const [createReceiverModal, setCreateReceiverModal] = useState<any>({
    open: false,
    customer_type: null,
    customer_id: null,
  });
  const [hideRateConChecked, setHideRateConChecked] = useState<any>(true);
  const [createIDModal, setCreateIDModal] = useState<boolean>(false);
  const [sharingList, setSharingList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>(null);
  const [shippersUsers, setShippersUsers] = useState<any>([
    {
      index: '',
      date: '',
      time: '',
      is_time_shown: 0,
      cargo_type: '',
      customer_id: '',
    },
  ]);
  const [receiversUsers, setReceiversUsers] = useState<any>([
    {
      index: '',
      date: '',
      time: '',
      is_time_shown: 0,
      cargo_type: '',
      customer_id: '',
    },
  ]);

  const [guestDriver, setGuestDriver] = useState<any>(null);
  const [sharingList_, setSharingList_] = useState<any>([]);
  const [equipmentTypesList, setEquipmentTypesList] = useState<any>([]);
  const [createEquipmentTypeModal, setCreateEquipmentTypeModal] =
    useState<boolean>(false);
  const [truckId, setTruckId] = useState<any>(null);
  const [trailerId, setTrailerId] = useState<any>(null);
  const [rateCon, setRateCon] = useState<any>({
    file: null,
    title: '',
    delete: 0,
    fullpath: null,
  });

  const [type, setType] = useState<any>(null);
  const [equipmentType, setEquipmentType] = useState<any>(null);
  const [selectedBillTo, setSelectedBillTo] = useState<any>(null);
  const [selectedDriver, setSelectedDriver] = useState<any>(null);
  const [selectedDispatcher, setSelectedDispatcher] = useState<any>(null);
  const [selectedTC, setSelectedTC] = useState<any>(null);

  const [loadInfo, setLoadInfo] = useState<any>(null);
  const [ownerId, setOwnerId] = useState<any>(null);
  const [readOnly, setReadOnly] = useState<any>(true);

  useEffect(() => {
    if (guestDriver) {
      setSelectedDriver({
        id: 'guest_driver',
        value: guestDriver.name,
      });
    }
  }, [guestDriver]);

  useEffect(() => {
    if (selectedDriver) {
      if (selectedDriver.id !== 'guest_driver') {
        setGuestDriver(null);
      }
      setTruckId(selectedDriver?.truck)
      setTrailerId(selectedDriver?.trailer)
    }

    
  }, [selectedDriver]);

  useEffect(() => {
    if (searchValue) {
      const result_: any = [];
      dispatch(searchFLorTitle(searchValue)).then((res: any) => {
        if (res.payload.errors.resource) {
          if (res.payload.errors.resource.length) {
            res.payload.errors.resource.map((result: any) => {
              result_.push({
                id: result.id,
                value: `${result.fl_number} | ${result.owner.title}`,
                fl_number: result.fl_number,
              });
            });

            setSharingList(result_);
          }
        }
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (ownerId && type_ && !readOnly) {
      const billTo: any = [];
      const customers: any = [];
      const drivers: any = [];
      const trucks: any = [];
      const trailers: any = [];
      const eqTypes: any = [];
      const indepDispatchers: any = [];

      const dataForGetListBillTo: any = {
        id: ownerId,
        type: type_,
        customer_type: 'bill_to',
      };

      const dataForGetListCustomers: any = {
        id: ownerId,
        type: type_,
        customer_type: 'customer',
      };

      const dataForDrivers: any = {
        id: ownerId,
        type: type_,
      };

      // dispatch(getIndependentDispatcherList()).then(res => {
      //   if (res.type === 'getIndependentDispatcherList/fulfilled') {
      //     if (res.payload.resource.data) {
      //       if (res.payload.resource.data.length) {
      //         res.payload.resource.data.map((user: any) => {
      //           let fullName = '';
      //           if (user.user.profile.first_name && user.user.profile.last_name) {
      //             fullName = `${user.user.profile.first_name} ${user.user.profile.last_name}`;
      //           } else if (user.user.profile.first_name && !user.user.profile.last_name) {
      //             fullName = user.user.profile.first_name;
      //           } else if (!user.user.profile.first_name && user.user.profile.last_name) {
      //             fullName = user.user.profile.last_name;
      //           }

      //           indepDispatchers.push({
      //             id: user.id,
      //             value: fullName.length ? fullName : user.title,
      //             title: fullName.length ? fullName : user.title,
      //           });
      //         });

      //         setIndependentDispatchersList(indepDispatchers);
      //       }
      //     }
      //   }
      // });

      dispatch(getCustomersList(dataForGetListBillTo)).then(res => {
        if (res.type === 'getCustomersList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((customer: any) => {
                let fullName = '';
                if (customer.first_name && customer.last_name) {
                  fullName = `${customer.first_name} ${customer.last_name}`;
                } else if (customer.first_name && !customer.last_name) {
                  fullName = customer.first_name;
                } else if (!customer.first_name && customer.last_name) {
                  fullName = customer.last_name;
                }

                billTo.push({ id: customer.id, value: fullName });
              });

              setBillToList(billTo);
            }
          }
        }
      });

      dispatch(getCustomersList(dataForGetListCustomers)).then(res => {
        if (res.type === 'getCustomersList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((customer: any) => {
                let fullName = '';
                if (customer.first_name && customer.last_name) {
                  fullName = `${customer.first_name} ${customer.last_name}`;
                } else if (customer.first_name && !customer.last_name) {
                  fullName = customer.first_name;
                } else if (!customer.first_name && customer.last_name) {
                  fullName = customer.last_name;
                }

                customers.push({
                  id: customer.id,
                  value: fullName,
                  address: customer.address.address,
                });
              });

              setCustomersList(customers);
            }
          }
        }
      });

      dispatch(getEquipmentTypes(dataForDrivers)).then(res => {
        if (res.type === 'getEquipmentTypes/fulfilled') {
          if (res.payload.resource.data.length) {
            res.payload.resource.data.map((type_: any) => {
              eqTypes.push({
                id: type_.id,
                value: type_.title,
              });
            });

            setEquipmentTypesList(eqTypes);
          }
        }
      });

      dispatch(getDriversList(1)).then(res => {
        if (res.type === 'getDriversList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((driver: any) => {
                let fullName = '';
                if (driver.profile.first_name && driver.profile.last_name) {
                  fullName = `${driver.profile.first_name} ${driver.profile.last_name}`;
                } else if (driver.profile.first_name && !driver.profile.last_name) {
                  fullName = driver.profile.first_name;
                } else if (!driver.profile.first_name && driver.profile.last_name) {
                  fullName = driver.profile.last_name;
                }

                drivers.push({ id: driver.id, value: fullName });
              });

              setDriversList(drivers);
            }
          }
        }
      });

      dispatch(getTrucksList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrucksList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((truck: any) => {
                trucks.push({ id: truck.id, value: truck.number });
              });

              setTrucksList(trucks);
            }
          }
        }
      });

      dispatch(getTrailersList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrailersList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((trailer: any) => {
                trailers.push({ id: trailer.id, value: trailer.number });
              });

              setTrailersList(trailers);
            }
          }
        }
      });
    }
  }, [ownerId, type_, readOnly]);

  useEffect(() => {
    if (loadInfo) {
      if (loadInfo.customer) {
        setSelectedBillTo({
          id: loadInfo.customer.id,
          value: getFullName(loadInfo.customer.first_name, loadInfo.customer.last_name),
        });
      }

      if (loadInfo.hide_rate_con === 1) {
        setHideRateConChecked(true);
      }
      if (loadInfo.hide_rate_con === 0) {
        setHideRateConChecked(false);
      }

      if (loadInfo.guest_driver) {
        setGuestDriver({
          email: loadInfo.guest_driver.email,
          name: loadInfo.guest_driver.name,
        });
      }

      if (loadInfo.equipment_type) {
        setEquipmentType({
          id: loadInfo.equipment_type.id,
          value: loadInfo.equipment_type.title,
        });
      }

      if (loadInfo.independent_dispatcher) {
        setSelectedDispatcher({
          id: loadInfo.independent_dispatcher.id,
          value: loadInfo.independent_dispatcher.title,
        });
      }

      if (loadInfo.truck_company) {
        setSelectedTC({
          id: loadInfo.truck_company.id,
          value: loadInfo.truck_company.title,
        });
      }

      if (loadInfo.driver && !loadInfo.guest_driver) {
        setSelectedDriver({
          id: loadInfo.driver.id,
          value: getFullName(
            loadInfo.driver.profile.first_name,
            loadInfo.driver.profile.last_name,
          ),
        });
      }

      if (loadInfo.shippers?.length) {
        const shippers_: any = [];
        setShippersCount(loadInfo.shippers.length);
        setShippersUsers(loadInfo.shippers);
        loadInfo.shippers.map((shipper: any) => {
          shippers_.push(shipper.index);
        });
        setShippers(shippers_);
      }

      if (loadInfo.receivers?.length) {
        const receivers_: any = [];
        setReceiversCount(loadInfo.receivers.length);
        setReceiversUsers(loadInfo.receivers);
        loadInfo.receivers.map((receiver: any) => {
          receivers_.push(receiver.index);
        });
        setReceivers(receivers_);
      }

      if (loadInfo.sharing?.length) {
        const sharing_: any = [];
        loadInfo.sharing.map((number: any) => {
          sharing_.push({
            fl_number: number.fl_number,
            value: `${number.fl_number} | ${number.owner.title}`,
          });
        });
        setSharingList_(sharing_);
      }

      if (loadInfo.truck) {
        setTruckId({
          id: loadInfo.truck.id,
          value: loadInfo.truck.number,
        });
      }

      if (loadInfo.trailer) {
        setTrailerId({
          id: loadInfo.trailer.id,
          value: loadInfo.trailer.number,
        });
      }

      if (loadInfo.load_type) {
        const myType = types.find((type_: any) => type_.value === loadInfo.load_type);
        if (myType) {
          setType(myType);
        }
      }

      if (loadInfo.rate_con) {
        setRateCon({
          file: null,
          title: loadInfo.rate_con.title,
        });
      }
      setOwnerId(loadInfo.owner_id);
      setReadOnly(true);
      // if (loadInfo.entity_id === +entityId) {
      //   setReadOnly(false);
      // } else {
      //   setReadOnly(true);
      // }
    }
  }, [loadInfo]);

  useEffect(() => {
      const billTo: any = [];
      const customers: any = [];
      const drivers: any = [];
      const trucks: any = [];
      const trailers: any = [];
      const indepDispatchers: any = [];

      const dataForGetListBillTo: any = {
        id,
        type: type_,
        customer_type: 'bill_to',
      };

      const dataForGetListCustomers: any = {
        id,
        type: type_,
        customer_type: 'customer',
      };

      const dataForDrivers: any = {
        id,
        type: type_,
      };

      if (params) {
        if (params.id) {
          dispatch(getSpecificLoad(params.id)).then((res: any) => {
            if (res.type === 'getSpecificLoad/fulfilled') {
              setLoadInfo(res.payload.data.resource);
            }
          });
        }
      }

      // dispatch(getIndependentDispatcherList()).then(res => {
      //   if (res.type === 'getIndependentDispatcherList/fulfilled') {
      //     if (res.payload.resource.data) {
      //       if (res.payload.resource.data.length) {
      //         res.payload.resource.data.map((user: any) => {
      //           let fullName = '';
      //           if (user.user.profile.first_name && user.user.profile.last_name) {
      //             fullName = `${user.user.profile.first_name} ${user.user.profile.last_name}`;
      //           } else if (user.user.profile.first_name && !user.user.profile.last_name) {
      //             fullName = user.user.profile.first_name;
      //           } else if (!user.user.profile.first_name && user.user.profile.last_name) {
      //             fullName = user.user.profile.last_name;
      //           }

      //           indepDispatchers.push({
      //             id: user.id,
      //             value: fullName.length ? fullName : user.title,
      //             title: fullName.length ? fullName : user.title,
      //           });
      //         });

      //         setIndependentDispatchersList(indepDispatchers);
      //       }
      //     }
      //   }
      // });

      dispatch(getCustomersList(dataForGetListBillTo)).then(res => {
        if (res.type === 'getCustomersList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((customer: any) => {
                let fullName = '';
                if (customer.first_name && customer.last_name) {
                  fullName = `${customer.first_name} ${customer.last_name}`;
                } else if (customer.first_name && !customer.last_name) {
                  fullName = customer.first_name;
                } else if (!customer.first_name && customer.last_name) {
                  fullName = customer.last_name;
                }

                billTo.push({ id: customer.id, value: fullName });
              });

              setBillToList(billTo);
            }
          }
        }
      });

      dispatch(getCustomersList(dataForGetListCustomers)).then(res => {
        if (res.type === 'getCustomersList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((customer: any) => {
                let fullName = '';
                if (customer.first_name && customer.last_name) {
                  fullName = `${customer.first_name} ${customer.last_name}`;
                } else if (customer.first_name && !customer.last_name) {
                  fullName = customer.first_name;
                } else if (!customer.first_name && customer.last_name) {
                  fullName = customer.last_name;
                }

                customers.push({
                  id: customer.id,
                  value: fullName,
                  address: customer.address.address,
                });
              });

              setCustomersList(customers);
            }
          }
        }
      });

      dispatch(getDriversList(1)).then(res => {
        if (res.type === 'getDriversList/fulfilled') {
          if (res.payload.resource.data) {
            if (res.payload.resource.data.length) {
              res.payload.resource.data.map((driver: any) => {
                
                let fullName = '';
                if (driver.profile.first_name && driver.profile.last_name) {
                  fullName = `${driver.profile.first_name} ${driver.profile.last_name}`;
                } else if (driver.profile.first_name && !driver.profile.last_name) {
                  fullName = driver.profile.first_name;
                } else if (!driver.profile.first_name && driver.profile.last_name) {
                  fullName = driver.profile.last_name;
                }

                drivers.push(
                  { id: driver.id, 
                    value: fullName,
                    trailer : {
                      id: driver.profile?.trailer_number_id,
                      value: driver.profile?.trailer_number
                    },
                    truck : {
                      id: driver.profile?.truck_number_id,
                      value: driver.profile?.truck_number
                    } 
                  }
                );
              });

              setDriversList(drivers);
            }
          }
        }
      });

      dispatch(getTrucksList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrucksList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((truck: any) => {
                trucks.push({ id: truck.id, value: truck.number });
              });

              setTrucksList(trucks);
            }
          }
        }
      });

      dispatch(getTrailersList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrailersList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((trailer: any) => {
                trailers.push({ id: trailer.id, value: trailer.number });
              });

              setTrailersList(trailers);
            }
          }
        }
      });
  }, [readOnly]);

  useEffect(() => {
    if (receiversUsers.length) {
      if (receiversUsers.length !== receiversCount) {
        setReceiversUsers([
          ...receiversUsers,
          {
            index: receiversCount,
            date: '',
            time: '',
            is_time_shown: 0,
            cargo_type: '',
            customer_id: '',
          },
        ]);

        setReceivers([...receivers, receiversCount]);
      }
    }
  }, [receiversCount]);

  useEffect(() => {
    if (shippersUsers.length) {
      if (shippersUsers.length !== shippersCount) {
        setShippersUsers([
          ...shippersUsers,
          {
            index: shippersCount,
            date: '',
            time: '',
            is_time_shown: 0,
            cargo_type: '',
            customer_id: '',
          },
        ]);

        setShippers([...shippers, shippersCount]);
      }
    }
  }, [shippersCount]);

  const onSubmit = async (data: any) => {
    const shippers: any = [];
    const receivers: any = [];

    const dataForCreate: any = {
      customer_id: selectedBillTo?.id || null,
      // driver_id: selectedDriver?.id || null,
      truck_id: truckId?.id || null,
      trailer_id: trailerId?.id || null,
      sales_rep: data.sales_rep || loadInfo.sales_rep,
      wo: data.confirmation_number || loadInfo.wo,
      rate: data.rate || loadInfo.rate,
      rate_usd: data.rate_usd || loadInfo.rate_usd,
      fsc: '',
      flat_rate: data?.flat_rate || loadInfo.flat_rate,
      other_charges: data.other_charges || loadInfo.other_charges,
      hide_rate_con: hideRateConChecked ? 1 : 0,
      equipment_type_id: equipmentType?.id || null,
      instructions: data.instructions || loadInfo.instructions || null,
    };

    if (type_ === 'id') {
      dataForCreate.independent_dispatcher_id = id;
    } else {
      dataForCreate.independent_dispatcher_id = selectedDispatcher?.id || null;
    }

    if (type) {
      if (type.value) {
        dataForCreate.load_type = type.value;
      } else {
        dataForCreate.load_type = type;
      }
    }

    if (data.shipper) {
      data.shipper.map((item: any, index: any) => {
        shippersUsers.map((user: any) => {
          if (user.index === index) {
            const dataForShipper: any = {
              index: index,
              date:
                user.dateAndTime && user.dateAndTime !== 'Invalid date'
                  ? user.dateAndTime
                  : user.date,
              is_time_shown: user?.is_time_shown,
              cargo_type: user?.cargo_type || null,
              qty: item?.qty || user.qty || null,
              weight: item?.weight || user?.weight || null,
              value: item?.value || user?.value || null,
              po_numbers: item?.po_numbers || user?.po_numbers || null,
              custom_fa: item?.custom_fa || user?.custom_fa || null,
              desc: item?.description || user?.desc || null,
              notes: item?.shipping_notes || user?.notes || null,
            };

            if (selectedShippers.length) {
              const findedId: any =
                selectedShippers.find((item: any) => item.index === user.index) || null;
              if (findedId) {
                dataForShipper.customer_id = findedId.customer_id;
              }
            }

            if (user.id) {
              dataForShipper.id = user.id
            }

            shippers.push(dataForShipper);
          }
        });
      });
    }

    if (data.receiver) {
      data.receiver.map((item: any, index: any) => {
        receiversUsers.map((user: any) => {
          if (user.index === index) {
            const dataForReceiver: any = {
              index: index,
              date:
                user.dateAndTime && user.dateAndTime !== 'Invalid date'
                  ? user.dateAndTime
                  : user.date,
              is_time_shown: user?.is_time_shown,
              cargo_type: user?.cargo_type || null,
              qty: item?.qty || user.qty || null,
              weight: item?.weight || user?.weight || null,
              value: item?.value || user?.value || null,
              po_numbers: item?.po_numbers || user?.po_numbers || null,
              desc: item?.description || user?.desc || null,
              notes: item?.shipping_notes || user?.notes || null,
            };

            if (selectedReceivers.length) {
              const findedId: any =
                selectedReceivers.find((item: any) => item.index === user.index) || null;
              if (findedId) {
                dataForReceiver.customer_id = findedId.customer_id;
              }
            }

            if (user.id) {
              dataForReceiver.id = user.id
            }

            receivers.push(dataForReceiver);
          }
        });
      });
    }

    dataForCreate.shippers = shippers;
    dataForCreate.receivers = receivers;
    dataForCreate.sharing = sharingList_.map((item: any) => item.fl_number);
    dataForCreate.load_id = params.id;

    if (rateCon.file) {
      dataForCreate.rate_con = { file: rateCon.file };
    }

    if (loadInfo.guest_driver) {
      if (guestDriver) {
        dataForCreate.guest_driver = guestDriver;
        dataForCreate.driver_id = null;
      } else {
        dataForCreate.guest_driver = false;
      }
    } else {
      if (guestDriver) {
        dataForCreate.guest_driver = guestDriver;
        dataForCreate.driver_id = null;
      }
    }

    if (selectedDriver && !guestDriver) {
      if (selectedDriver.id !== 'guest_driver') {
        dataForCreate.driver_id = selectedDriver?.id || null;
      }
    }

    dispatch(editLoad(dataForCreate)).then((res: any) => {
      if (res.type === 'updateLoad/fulfilled') {
        navigate('/loads');
      }
    });
  };

  const onDeleteSharing = (sharing: any) => {
    const notDeletedItems: any = [];
    sharingList_.map((item: any) => {
      if (item.fl_number !== sharing.fl_number) {
        notDeletedItems.push(item);
      }
    });

    setSharingList_(notDeletedItems);
  };

  return (
    <>
      <Loader loading={false} />

      {loadInfo && (
        <div className="createDriver mainContainer createLoadContainer">
          <div className="createDriver__container">
            <div className="createDriver__body">
              <div className="createDriver__userForm">
                <div className="createDriver__header">
                  <div className="createDriver__title">
                    {readOnly ? <h1>Load Details</h1> : <h1>Edit Load</h1>}
                  </div>

                  <Button
                    title="BACK"
                    Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                    onClick={() => navigate('/loads')}
                    className="mapBtn"
                    variant="outlined"
                  />
                </div>
              </div>

              <div className="createLoadForm">
                <div className="sharingAndDriver">
                  <div className="sharingContainer">
                    <AsyncAutocompleteComponent
                      items={sharingList}
                      label="Add FL# To connect TC/FA/ID"
                      setSearchValue={setSearchValue}
                      setSharingList={setSharingList_}
                      sharingList={sharingList_}
                      readOnly={readOnly}
                      className="sharingInput"
                    />

                    <div className="selectedSharingContainer">
                      {sharingList_.map((number: any) => {
                        return (
                          <div key={number.value} className="selectedSharing">
                            {number.value}
                            {!readOnly && (
                              <div
                                className="deleteSharing"
                                onClick={() => onDeleteSharing(number)}
                              >
                                Delete
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {guestDriver ? (
                    <div className="guestDriverInfo">
                      <div>{guestDriver.name}</div>
                      <div className="guestDriverEmail">
                        {guestDriver.email}{' '}
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                          className="deleteBtnAction"
                          onClick={() => {
                            setGuestDriver(null);
                            setSelectedDriver(null);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    readOnly ? null : <ButtonComponent
                      title="+ADD GUEST DRIVER"
                      onClick={() => setCreateGuestDriverModal(true)}
                      variant="contained"
                      className="guestDriver-btn"
                    />
                  )}
                </div>
                <div className="customerFormContainer">
                  <div>
                    <h2 className="loadBlockTitle">Load Information</h2>

                    <div className="increasingBlocks">
                      <div className={'increasingBlock increasingBlockSelected'}>
                        Customer
                      </div>
                    </div>

                    <div className="customerForm">
                      <div className="general__line-1">
                        <div className="inputsContainer inputFlexOne">
                          <ContainedInput
                            label="Load Number"
                            className="marginRight inputFlexOne"
                            control={control}
                            id="load_number"
                            readOnly
                            defaultValue={loadInfo.load_number}
                          />
                          <AutocompleteComponent
                            items={billToList}
                            label="Bill To"
                            className="inputFlexOne"
                            setModalOpen={setCreateBillToModal}
                            setValueForInput={setSelectedBillTo}
                            defaultValue={selectedBillTo}
                            disabled={readOnly}
                            control={control}
                          />
                        </div>
                        <div className="inputsContainer inputFlexOne">
                          {type_ === 'id' && (
                            <ContainedInput
                              readOnly={readOnly}
                              label="Dispatcher"
                              className="marginRight inputFlexOne"
                              control={control}
                              id="dispatcher"
                              defaultValue={
                                parsedData
                                  ? parsedData.first_name && parsedData.last_name
                                    ? `${parsedData.first_name} ${parsedData.last_name}`
                                    : parsedData.company_name
                                  : null
                              }
                            />
                          )}
                          {type_ === 'fa' && blockAccess && (
                            <ContainedInput
                              label="Dispatcher"
                              className="marginRight inputFlexOne"
                              control={control}
                              readOnly
                            />
                          )}
                          {type_ !== 'id' && !blockAccess && (
                            <AutocompleteComponent
                              items={independentDispatchersList}
                              label="Dispatcher"
                              className="inputFlexOne"
                              setModalOpen={setCreateIDModal}
                              setValueForInput={setSelectedDispatcher}
                              defaultValue={selectedDispatcher}
                              disabled={readOnly}
                              control={control}
                            />
                          )}
                          <ContainedInput
                            label="Sales Rep"
                            className="marginRight inputFlexOne"
                            control={control}
                            id="sales_rep"
                            readOnly={readOnly}
                            defaultValue={loadInfo.sales_rep}
                          />
                        </div>
                        <div className="inputsContainer inputFlexOne">
                          <ContainedInput
                            label="Status"
                            className="inputFlexOne marginRight"
                            control={control}
                            id="confirmation_number"
                            defaultValue={loadInfo.status}
                            readOnly
                          />
                          <ContainedInput
                            label="Confirmation #"
                            className="inputFlexOne"
                            control={control}
                            id="confirmation_number"
                            readOnly={readOnly}
                            defaultValue={loadInfo.wo}
                          />
                        </div>
                      </div>

                      <div className="general__line-1">
                        <div className="inputsContainer inputFlexOne">
                          <Selection
                            label="Type"
                            className="marginRight customWidthState inputFlexOne"
                            items={types}
                            name="type"
                            setValue={setType}
                            defaultValue={type}
                            readOnly={readOnly}
                            control={control}
                          />
                          <ContainedInput
                            label="Rate"
                            className="marginRight inputFlexOne"
                            control={control}
                            id="rate"
                            defaultValue={loadInfo?.rate ? loadInfo.rate : '0.00'}
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="inputsContainer inputFlexOne">
                          <ContainedInput
                            label="Other Charges"
                            className="marginRight inputFlexOne"
                            control={control}
                            id="other_charges"
                            type="price"
                            defaultValue={
                              loadInfo?.other_charges ? loadInfo.other_charges : '0.00'
                            }
                            readOnly={readOnly}
                          />
                          <ContainedInput
                            label="Rate USD $"
                            className="marginRight inputFlexOne"
                            control={control}
                            id="rate_usd"
                            type="price"
                            defaultValue={loadInfo?.rate_usd ? loadInfo.rate_usd : '0.00'}
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="inputsContainer inputFlexOne"></div>
                      </div>

                      <div className="general__line-1">
                        <div className="inputsContainer inputFlexOne">
                          <AutocompleteComponent
                            items={[]}
                            label="Truck Company*"
                            // labelId="truckCompany"
                            className="inputFlexOne"
                            // setModalOpen={setCreateDriverModal}
                            // setEditModalOpen={onHandleEditField}
                            // setDeleteModalOpen={onHandleDeleteField}
                            // setValueForInput={setSelectedTC}
                            defaultValue={selectedTC}
                            // editable={true}
                            withoutAddNew={true}
                            disabled={true}
                            control={control}
                          />

                          <AutocompleteComponent
                            items={driversList}
                            label="Driver"
                            className="inputFlexOne"
                            setModalOpen={setCreateDriverModal}
                            setValueForInput={setSelectedDriver}
                            defaultValue={selectedDriver}
                            disabled={readOnly}
                            control={control}
                          />
                        </div>
                        <div className="inputsContainer inputFlexOne">
                          <AutocompleteComponent
                            items={equipmentTypesList}
                            label="Equipment Type"
                            className="inputFlexOne marginRight"
                            setModalOpen={setCreateEquipmentTypeModal}
                            defaultValue={equipmentType}
                            setValueForInput={setEquipmentType}
                            disabled={readOnly}
                            control={control}
                          />
                          <AutocompleteComponent
                            items={trucksList}
                            label="Truck"
                            className="inputFlexOne"
                            setModalOpen={setCreateTruckModal}
                            defaultValue={truckId}
                            // valueId={truckId}
                            disabled={readOnly}
                            control={control}
                            setValueForInput={setTruckId}
                          />
                        </div>
                        <div className="inputsContainer inputFlexOne">
                          <AutocompleteComponent
                            items={trailersList}
                            label="Trailer"
                            className="inputFlexOne"
                            setModalOpen={setCreateTrailerModal}
                            defaultValue={trailerId}
                            // valueId={trailerId}
                            disabled={readOnly}
                            control={control}
                            setValueForInput={setTrailerId}
                          />
                          <ContainedInput
                            label="Flat Rate"
                            className="inputFlexOne"
                            control={control}
                            id="flat_rate"
                            defaultValue={
                              loadInfo.flat_rate ? loadInfo.flat_rate : '0.00'
                            }
                            readOnly={readOnly}
                          />
                        </div>
                      </div>

                      <div className="general__line-1">
                        <div className="inputsContainer inputFlexTwo">
                          <ContainedInput
                            label="Special Instructions and Confirmation"
                            className="marginRight inputFlexTwo"
                            control={control}
                            id="instructions"
                            readOnly={readOnly}
                            defaultValue={loadInfo?.instructions}
                          />
                        </div>
                        <UploadButton
                          readOnly={readOnly}
                          label="Rate Con"
                          className="inputFlexOne"
                          fileFullName={''}
                          changeValue={setRateCon}
                          value={rateCon}
                          anotherLabel={
                            <div className="label showTimeLabel labelRateCon">
                              Rate Con
                              <div>
                                <Checkbox
                                  setCheckedValue={setHideRateConChecked}
                                  defaultChecked={hideRateConChecked}
                                />{' '}
                                Hide the Rate Con from the Driver
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="shipperFormContainer">
                  <h2 className="loadBlockTitle">Shipper Information</h2>

                  <div className="increasingBlocks">
                    {shippersUsers.length &&
                      shippersUsers.map((shipper: any) => {
                        return (
                          <div
                            className={`increasingBlock ${
                              selectedShipper === shipper.index
                                ? 'increasingBlockSelected'
                                : ''
                            }`}
                            key={shipper}
                            onClick={() => setSelectedShipper(shipper.index)}
                          >
                            Shipper {shipper.index}
                          </div>
                        );
                      })}
                    {!readOnly && (
                      <div
                        className="addIncreasingBlockBtn"
                        onClick={() => setShippersCount(shippersCount + 1)}
                      >
                        +
                      </div>
                    )}
                  </div>

                  {shippers.map((shipper: any) => {
                    return (
                      <Shipper
                        control={control}
                        shipperId={shipper}
                        key={`shipper-${shipper}`}
                        isSelected={selectedShipper === shipper}
                        items={customersList}
                        setModalOpen={setCreateShipperModal}
                        openModal={createShipperModal}
                        setShippersUsers={setShippersUsers}
                        shippersUsers={shippersUsers}
                        readOnly={readOnly}
                        setSelectedUsers={setSelectedShippers}
                        selectedUsers={selectedShippers}
                      />
                    );
                  })}
                </div>

                <div className="receiverFormContainer">
                  <h2 className="loadBlockTitle">Receiver Information</h2>

                  <div className="increasingBlocks">
                    {receiversUsers.length &&
                      receiversUsers.map((receiver: any) => {
                        return (
                          <div
                            className={`increasingBlock ${
                              selectedReceiver === receiver.index
                                ? 'increasingBlockSelected'
                                : ''
                            }`}
                            key={receiver}
                            onClick={() => setSelectedReceiver(receiver.index)}
                          >
                            Receiver {receiver.index}
                          </div>
                        );
                      })}
                    {!readOnly && (
                      <div
                        className="addIncreasingBlockBtn"
                        onClick={() => setReceiversCount(receiversCount + 1)}
                      >
                        +
                      </div>
                    )}
                  </div>

                  {receivers.map((receiver: any) => {
                    return (
                      <Receiver
                        control={control}
                        receiverId={receiver}
                        key={`shipper-${receiver}`}
                        isSelected={selectedReceiver === receiver}
                        items={customersList}
                        setModalOpen={setCreateReceiverModal}
                        setReceiversUsers={setReceiversUsers}
                        receiversUsers={receiversUsers}
                        readOnly={readOnly}
                        setSelectedUsers={setSelectedReceivers}
                        selectedUsers={selectedReceivers}
                      />
                    );
                  })}
                </div>
              </div>
              {!readOnly && (
                <div className="buttonsContainer">
                  <Button
                    title="SAVE"
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    className="saveBtn saveLoadBtn"
                    loading={submitLoading}
                  />
                </div>
              )}

              {
                <>
                  {createBillToModal && (
                    <CreateCustomerModal
                      open={createBillToModal}
                      setOpen={setCreateBillToModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      usersList={billToList}
                      setUsersList={setBillToList}
                      customerType="bill_to"
                      setSelectedCustomer={setSelectedBillTo}
                    />
                  )}

                  {createShipperModal.open && (
                    <CreateCustomerModal
                      open={createShipperModal}
                      setOpen={setCreateShipperModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      usersList={customersList}
                      setUsersList={setCustomersList}
                      customerType="customer"
                    />
                  )}

                  {createReceiverModal.open && (
                    <CreateCustomerModal
                      open={createReceiverModal}
                      setOpen={setCreateReceiverModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      usersList={customersList}
                      setUsersList={setCustomersList}
                      customerType="customer"
                    />
                  )}

                  {createDriverModal && (
                    <CreateDriverModal
                      open={createDriverModal}
                      setOpen={setCreateDriverModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      usersList={driversList}
                      setUsersList={setDriversList}
                      setSelectedDriver={setSelectedDriver}
                    />
                  )}

                  {createGuestDriverModal && (
                    <CreateGuestDriverModal
                      open={createGuestDriverModal}
                      setOpen={setCreateGuestDriverModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      setGuestDriver={setGuestDriver}
                    />
                  )}

                  {createIDModal && (
                    <CreateDispatcherModal
                      open={createIDModal}
                      setOpen={setCreateIDModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      usersList={independentDispatchersList}
                      setUsersList={setIndependentDispatchersList}
                      setSelectedDispatcher={setSelectedDispatcher}
                    />
                  )}

                  {createEquipmentTypeModal && (
                    <CreateEquipmentTypeModal
                      open={createEquipmentTypeModal}
                      setOpen={setCreateEquipmentTypeModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      usersList={equipmentTypesList}
                      setUsersList={setEquipmentTypesList}
                      setEquipmentType={setEquipmentType}
                    />
                  )}

                  {createTruckModal && (
                    <CreateTruckModal
                      open={createTruckModal}
                      setOpen={setCreateTruckModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      usersList={trucksList}
                      setUsersList={setTrucksList}
                      setTruckId_={setTruckId}
                    />
                  )}

                  {createTrailerModal && (
                    <CreateTrailerModal
                      open={createTrailerModal}
                      setOpen={setCreateTrailerModal}
                      onChange={() => null}
                      onSubmit={() => null}
                      confirmTitle="ADD"
                      cancelTitle="CANCEL"
                      contentTitle=""
                      formType="create"
                      loading={submitLoading}
                      usersList={trailersList}
                      setUsersList={setTrailersList}
                      setTrailerId_={setTrailerId}
                    />
                  )}
                </>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditLoad;
