import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/global";
import { getLoadList } from "../../../store/loads/loads";
import { getDriversList } from "../../../store/driver/driver";
import { getSpecificLoad } from "../../../store/specificLoad/specificLoad";
import { Button, Pagination, Portal } from "../../controls";
import Loader from "../../common/Loader/Loader";
import TableComponent from "../Dashboard/parts/Table";
import Filters from "../Dashboard/parts/Filters/Filters";
import { DataPack } from "../Dashboard/parts/DataPack/DataPack";
import { ModalMap } from "../Dashboard/parts/ModalMap/ModalMap";
import { ROUTES } from "../../../router/routes";
import moment from "moment";
import "./LoadsList.scss";

interface ILoadsList {
  blockAccess?: boolean;
}

const LoadsList: FC<ILoadsList> = ({ blockAccess }) => {
  const [amountPages, setAmountPages] = useState<number>(1);
  const [sort, setSort] = useState<null | string>(null);
  const [page, setPage] = useState<number>(
    sessionStorage.getItem("page") ? Number(sessionStorage.getItem("page")) : 1
  );
  const [driversList, setDriversList] = useState<any>([]);
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [status, setStatus] = useState<string | null>(
    sessionStorage.getItem("status")
  );
  const [driver, setDriver] = useState<string | null>(
    sessionStorage.getItem("driver")
  );
  const [dateValue, setDate] = useState<string | null>(
    sessionStorage.getItem("date")
  );
  const [formattedDate, setFormattedDate] = useState<string | null>(
    sessionStorage.getItem("date")
  );
  const [typeLoads, setTypeLoads] = useState<string | null>(
    sessionStorage.getItem("type") ? sessionStorage.getItem("type") : "all"
  );
  const [specificLoad, setSpecificLoad] = useState<null | number>(null);
  const [dataPack, setDataPack] = useState<boolean>(false);
  const [openModalMap, setOpenModalMap] = useState<boolean>(false);
  const [typeMediaOpen, setTypeMediaOpen] = useState<string>("");
  const [resetted, setResetted] = useState(false);

  const [companyValue, setCompanyValue] = useState(null);

  const { loadsList, loading } = useAppSelector((state) => state.loads);

  const goToMap = () => navigate(ROUTES.LOADS_MAP);
  const goToNewLoad = () => navigate(ROUTES.ADD_NEW_LOAD);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (loadsList && loadsList.data && loadsList.data.resource) {
      setAmountPages(
        loadsList.data.resource.last_page
          ? loadsList.data.resource.last_page
          : 1
      );
    }
  }, [loadsList, sort]);

  const changeSortType = (sortType: string | null) => {
    setSort(sortType);
    setSortDirection((sortDirection) =>
      sortDirection === "asc" ? "desc" : "asc"
    );
    setPage(1);
  };

  const getList = (page: number) => {
    dispatch(
      getLoadList({
        page,
        sort,
        sortDirection,
        status,
        formattedDate,
        driver,
        typeLoads,
        company: companyValue,
      })
    );
  };

  useEffect(() => {
    if (resetted && driver === "" && status === "" && typeLoads === "all" && companyValue === "" && !dateValue) {
      setResetted(false);
      getList(page);
    }
  }, [resetted, driver, status, dateValue, typeLoads, companyValue ])

  const onHandleSearch = () => {
    getList(page);
  };

  const changePage = (page: number) => {
    setPage(page);
    sessionStorage.setItem("page", page.toString());
    getList(page);
  };

  useEffect(() => {
    getList(page);
  }, []);

  const changedataPack = (type: boolean) => setDataPack(type);
  const changeOpenModalMap = (type: boolean) => setOpenModalMap(type);

  const setPopup = (load: number, type: string) => {
    setDataPack(true);
    setTypeMediaOpen(type);
    setSpecificLoad(load);
    const root: HTMLElement | null = document.querySelector("html");
    root ? (root.style.overflow = "hidden") : null;
    root ? (root.style.paddingRight = "17px") : null;
  };

  const setModalMap = (load: any) => {
    dispatch(getSpecificLoad(load));
    setOpenModalMap(true);
    setSpecificLoad(load);
  };

  const changeTypeLoads = (type: string) => {
    sessionStorage.setItem("type", type);
    setTypeLoads(type);
  };
  const changeDriver = (id: string) => {
    setDriver(id === "all" ? "" : id);
    sessionStorage.setItem("driver", id === "all" ? "" : id);
  };

  const changeStatus = (status: string) => {
    sessionStorage.setItem("status", status === "all" ? "" : status);
    setStatus(status === "all" ? "" : status);
  };

  const changeDate = (date: string) => {
    setFormattedDate(moment(date).format("YYYY-MM-DD"));
    sessionStorage.setItem("date", moment(date).format("YYYY-MM-DD"));
    setDate(date);
  };

  const id = localStorage.getItem("owner_id");
  const userData = localStorage.getItem("user");
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  // useEffect(() => {
  //   if (id) {
  //     const billTo: any = [];
  //     const customers: any = [];
  //     const drivers: any = [];
  //     const indepDispatchers: any = [];
  //     const type =
  //       parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
  //         ? 'tc'
  //         : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
  //         ? 'fa'
  //         : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
  //         ? 'id'
  //         : null;

  //     const dataForDrivers: any = {
  //       id,
  //       type,
  //     };

  //     dispatch(getDriversList()).then(res => {
  //       if (res.type === 'getDriversList/fulfilled') {
  //         if (res.payload.resource.data) {
  //           if (res.payload.resource.data.length) {
  //             res.payload.resource.data.map((driver: any) => {
  //               let fullName = '';
  //               if (driver.profile.first_name && driver.profile.last_name) {
  //                 fullName = `${driver.profile.first_name} ${driver.profile.last_name}`;
  //               } else if (driver.profile.first_name && !driver.profile.last_name) {
  //                 fullName = driver.profile.first_name;
  //               } else if (!driver.profile.first_name && driver.profile.last_name) {
  //                 fullName = driver.profile.last_name;
  //               }

  //               drivers.push({ id: driver.id, value: fullName });
  //             });
  //             setDriversList(drivers);
  //           }
  //         }
  //       }
  //     });
  //   }
  // }, []);

  return (
    <>
      <Loader loading={loading} />
      <Portal>
        <DataPack
          changedataPack={changedataPack}
          dataPack={dataPack}
          loadId={specificLoad}
          typeMediaOpen={typeMediaOpen}
        />
      </Portal>
      {openModalMap && (
        <ModalMap
          changeOpenModalMap={changeOpenModalMap}
          loadId={specificLoad}
          openModalMap={openModalMap}
        />
      )}
      <div className="loadsList mainContainer">
        <div className="loadsList__container">
          <div className="loadsList__header">
            <h1>Loads</h1>
          </div>
          <div className="loadsList__body">
            <div className="componentHeader">
              <h2
                className="componentHeader__title"
                style={{ marginTop: "-10px" }}
              >
                Loads List
                <button className="btn-header _full-map" onClick={goToMap}>
                  <span>Full Map</span>
                </button>
              </h2>
              <div>
                <Filters
                  drivers={driversList}
                  changeDriver={changeDriver}
                  status={status}
                  changeStatus={changeStatus}
                  driver={driver}
                  dateValue={dateValue}
                  changeDate={changeDate}
                  changeTypeLoads={changeTypeLoads}
                  typeLoads={typeLoads}
                  companyValue={companyValue}
                  setCompanyValue={setCompanyValue}
                  setResetted={setResetted}
                />

                <Button
                  title="SEARCH"
                  onClick={() => onHandleSearch()}
                  variant="contained"
                  className="searchBtn"
                />
              </div>
            </div>
            {loadsList && (
              <TableComponent
                list={loadsList}
                changeSortType={changeSortType}
                setPopup={setPopup}
                setModalMap={setModalMap}
              />
            )}
            <div className="pagination__body">
              <div className="totalList">
                Displaying &nbsp;
                {loadsList && loadsList.data
                  ? !loadsList.data.resource.to
                    ? 0
                    : loadsList.data.resource.to
                  : 1}
                &nbsp; of &nbsp;
                {loadsList && loadsList.data
                  ? loadsList.data.resource.total
                  : 1}
              </div>
              {loadsList &&
              loadsList.data &&
              loadsList.data.resource &&
              loadsList.data.resource.data &&
              loadsList.data.resource.data.length > 0 ? (
                <Pagination
                  pageNumber={page}
                  changePage={changePage}
                  amountPages={amountPages}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadsList;
