import { FC, useState, useEffect } from 'react';
import Moment from 'react-moment';
import GoogleMap from 'google-map-react';
import './MapWithMarkers.scss';

interface IMapProps {
  markers?: [] | any;
  location: object | any;
  style?: object | any;
  zoom?: number;
  selected?: number;
  onPress?: any;
}

const MapWithMarkers: FC<IMapProps> = ({
  markers = [],
  location = { lat: 0, lng: 0 },
  style = { height: '100px', width: '100px' },
  zoom = 10,
  onPress,
}) => {
  const [selected, setSelected] = useState<any>(false);
  const [latCenter, setLatCenter] = useState<any>(40.0);
  const [lngCenter, setLngCenter] = useState<any>(-70.0);
  const [zoomMap, setZoomMap] = useState<number>(zoom);

  useEffect(() => {
    setLatCenter(markers[0] ? +markers[0].lat : 40.0);
    setLngCenter(markers[0] ? +markers[0].lng : -70.0);
    if (markers.length < 1) {
      setZoomMap(5);
    } else {
      setZoomMap(6);
    }
  }, [markers]);

  const bounds = new window.google.maps.LatLngBounds();

  const Marker = (props: any) => {
    const { name, option, city, date, treiler, truck, phone, nameDriver, id, lat, lng } =
      props;
      const location = new window.google.maps.LatLng(
        lat,
        lng
      );
      bounds.extend(location);
    return (
      <div
        onClick={(e: any) => {
          setSelected((selected: any) => (selected === id ? null : id));
          setLatCenter(lat);
          setLngCenter(lng);
        }}
        className={`marker ${option}`}
        style={{ cursor: 'pointer' }}
        title={name}
      >
        <div className="valueMarker">
          {selected === id ? (
            <div className="infoMarker">
              <div className="wrap-info">
                <p className="wrap-info_tittle">{city}</p>
                <div className="wrap-info_subtitle">ETA</div>
                <div className="wrap-info_table">
                  <p>
                    <span>Date:</span>
                    <span>
                      {date ? <Moment format="MM/DD/YYYY">{date}</Moment> : ' - '}
                    </span>
                  </p>
                  <p>
                    <span>Time:</span>
                    <span>{date ? <Moment format="hh:mm A">{date}</Moment> : ' - '}</span>
                  </p>
                  <p>
                    <span>Driver:</span>
                    <span>{nameDriver}</span>
                  </p>
                  <p>
                    <span>Truck #:</span>
                    <span>{truck ? truck : ' - '}</span>
                  </p>
                  <p>
                    <span>Trailer #:</span>
                    <span>{treiler ? treiler : ' - '}</span>
                  </p>
                  {/* <p>
                    <span>Phone #:</span>
                    <span> +{phone}</span>
                  </p> */}
                </div>
              </div>
            </div>
          ) : null}

          {name}
        </div>
      </div>
    );
  };

  const arrayOfMarkers = markers.map((marker: any) => {
    const { lat, lng, load, id, city, treiler, truck, name, date, phone, status } =
      marker;

    return (
      <Marker
        lat={+lat}
        lng={+lng}
        name={load}
        key={id}
        option={status}
        city={city}
        treiler={treiler}
        truck={truck}
        phone={phone}
        nameDriver={name}
        date={date}
        id={id}
      />
    );
  });

  return (
    <div style={{ height: '70vh', width: '100%' }}>
      <GoogleMap
        bootstrapURLKeys={{ key: 'AIzaSyB_BILzV8xNVsvMnxSJ13GsNaaoS0LVIeY' }}
        center={{
          lat: latCenter,
          lng: lngCenter,
        }}
        defaultZoom={zoomMap}
        onGoogleApiLoaded={({ map }) => map.fitBounds(bounds)}
      >
        {arrayOfMarkers}
      </GoogleMap>
    </div>
  );
};

export default MapWithMarkers;
