import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { displayProtectedImage, displayImageSrc } from '../../../helpers';
import { useAppDispatch } from '../../../hooks/global';
import { getReportDamage, getReportDamagePdf } from '../../../store/reports/reports';
import { IReportDamage } from './interfaces/interface';
import { Button } from '../../controls';
import Loader from '../../common/Loader/Loader';
import { ModalPicture } from '../../controls';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import '../ReportAccident/ReportIncident.scss'

interface IReportIncident {
  partialBlockAccess?: boolean;
}

const ReportDamage: FC<IReportIncident> = ({ partialBlockAccess }) => {
  const [isGeneralInfo, setGeneralInfo] = useState(true);
  const [isYourVehicleInfo, setYourVehicleInfo] = useState(false);
  const [isOtherVehicleInfo, setOtherVehicleInfo] = useState(false);
  const [isIncidentPictures, setIncidentPictures] = useState(false);
  const [loadInfo, setLoadInfo] = useState<IReportDamage | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [open, setOpen] = useState(false);
  const [arrayImages, setArrayImages] = useState<string[]>([]);

  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const goToReportsList = () => navigate('/reports-list');

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getReportDamage({
          id: params.id,
        }),
      ).then((res: any) => {
        if (res.type === 'getReportDamage/fulfilled') {
          if (res.payload.resource) {
            setLoadInfo(res.payload.resource);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (loadInfo) setLoading(false);
    
    if(loadInfo?.pictures &&
      loadInfo?.pictures?.length > 0 ){ loadInfo.pictures.forEach((picture: any) => {
          displayProtectedImage(picture?.id, picture.thumbnail ? picture.thumbnail : "", token);
          setArrayImages(arrayImages => arrayImages = [...arrayImages, picture?.thumbnail])
      })}
  }, [loadInfo]);

  const handleOpen = (picture : string) => {
    setOpen(true);
    displayImageSrc("modal", picture, token);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPdf = () => {
    if (params.id) {
      dispatch(getReportDamagePdf({id: params.id}))
    }
  }

  return (
    <>
      <Loader loading={loading} />
      <div className="reportIncident mainContainer">
        <div className="reportIncident__container">
          <div className="reportIncident__body">
            <div className="reportIncident__header">
              <div className="reportIncident__title">
                <h1>Report</h1>
              </div>

              <div className="reportIncident__btnWrapper">
                <Button
                  onClick={() => goToReportsList()}
                  Icon={<KeyboardBackspaceTwoToneIcon />}
                  className="backBtn"
                  variant="outlined"
                  title={'back'}
                />
              </div>
            </div>
            <div className="componentHeader">
              <h2 className="componentHeader__title">
                Equipment/Loss Freight Damage Report (
                {loadInfo?.load_number ? loadInfo?.load_number : ' - '})
              </h2>
            </div>
            <div className="reportIncident__main">
              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">General Information</h2>

                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setGeneralInfo(!isGeneralInfo)}
                    className={`accContainer__openBtn ${!isGeneralInfo ? '-opened' : ''}`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isGeneralInfo ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accInfoList">
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Load Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.load_number ? loadInfo?.load_number : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Date | Time:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.date ? (
                            <Moment format="MM/DD/YYYY">{loadInfo?.date}</Moment>
                          ) : (
                            ' - '
                          )}
                          |
                          {loadInfo?.date ? (
                            <Moment format="hh:mm A">{loadInfo?.date}</Moment>
                          ) : (
                            ' - '
                          )}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Location:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.location ? loadInfo?.location : ' - '}
                        </div>
                      </li>                      
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Reporting Employee</h2>

                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setYourVehicleInfo(!isYourVehicleInfo)}
                    className={`accContainer__openBtn ${
                      !isYourVehicleInfo ? '-opened' : ''
                    }`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isYourVehicleInfo ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accInfoList">
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Employee Name: **</div>
                        <div className="accInfoList__value">
                          {loadInfo?.employee_name ? loadInfo?.employee_name : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Contact Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.contact_number ? loadInfo?.contact_number : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Truck Number: **</div>
                        <div className="accInfoList__value">
                          {loadInfo?.track_number ? loadInfo?.track_number : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Trailer Number:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.trailer_number ? loadInfo?.trailer_number : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Equipment/ Lost/ Possible Freight Claim Details:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.freight_claim_details ? loadInfo?.freight_claim_details : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Damaged or Lost Equipment:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.damage? loadInfo?.damage : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Possible Freight Claim Load #: **</div>
                        <div className="accInfoList__value">
                          {loadInfo?.claim_load_number ? loadInfo?.claim_load_number : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">
                        Description of Equipment/ Lost/ Possible Freight Claim:
                        </div>
                        <div className="accInfoList__value">
                          {loadInfo?.claim_details ? loadInfo?.claim_details : ' - '}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Pictures of Equipment/Loss/Freight Damage</h2>

                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setIncidentPictures(!isIncidentPictures)}
                    className={`accContainer__openBtn ${
                      !isIncidentPictures ? '-opened' : ''
                    }`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isIncidentPictures ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accPictureList">
                      {loadInfo?.pictures &&
                      loadInfo?.pictures?.length > 0
                        ? loadInfo.pictures.map((picture: any) => {
                            return (
                              <li key={picture?.id} className="accPictureList__item">
                                <div
                                  className="accPictureList__itemBtn"
                                  onClick={() => handleOpen(picture.file ? picture.file : "")}
                                  id={picture?.id}
                                >
                                </div>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Witnesses Contact Information</h2>
                  <img
                    src={require('../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setOtherVehicleInfo(!isOtherVehicleInfo)}
                    className={`accContainer__openBtn ${
                      !isOtherVehicleInfo ? '-opened' : ''
                    }`}
                  />
                </div>

                <div
                  className={`accContainer__contentWrapper ${
                    isOtherVehicleInfo ? '-opened' : ''
                  }`}
                >
                  <div className="accContainer__content">
                    <ul className="accInfoList">
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Witness Name 1:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_name_1 ? loadInfo?.witness_name_1 : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Phone Number 1:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_phone_1 ? loadInfo?.witness_phone_1 : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Witness Name 2:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_name_2 ? loadInfo?.witness_name_2 : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Phone Number 2:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_phone_2
                            ? loadInfo?.witness_phone_2
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Witness Name 3:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_name_3 ? loadInfo?.witness_name_3 : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Phone Number 3:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.witness_phone_3
                            ? loadInfo?.witness_phone_3
                            : ' - '}
                        </div>
                      </li>
                      <li className="accInfoList__row">
                        <div className="accInfoList__title">Notes:</div>
                        <div className="accInfoList__value">
                          {loadInfo?.notes
                            ? loadInfo?.notes
                            : ' - '}
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Signature</h2>
                </div>

                <div className={`accContainer__contentWrapper -opened`}>
                  <div className="accContainer__content">
                    <div className="incidentFooterInfo">
                      <div className="incidentFooterInfo__row">
                        <div className="incidentFooterInfo__col">
                          <div className="incidentFooterInfo__title">Sign:</div>
                          <div className="incidentFooterInfo__value">
                            {loadInfo?.sign ? loadInfo?.sign : ' - '}
                          </div>
                        </div>
                        <div className="incidentFooterInfo__col">
                          <div className="incidentFooterInfo__title">Date:</div>
                          <div className="incidentFooterInfo__value">
                            {loadInfo?.sign_date ? (
                              <Moment format="MM/DD/YYYY">{loadInfo?.sign_date}</Moment>
                            ) : (
                              ' - '
                            )}
                          </div>
                        </div>
                        <div className="incidentFooterInfo__col">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Button title="EXPORT" onClick={() => getPdf()} variant="contained" className="exportBtn"/>
            </div>
          </div>
        </div>
      </div>
      <ModalPicture
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default ReportDamage;
