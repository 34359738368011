export enum ROUTES {
  HOME = '/',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  RECOVERY_PASSWORD = '/recovery-password',
  RESTORE_PASSWORD = '/restore-password',
  DASHBOARD = '/dashboard',
  MY_PROFILE = '/my-profile',
  EMAIL_VERIFY = '/email-verify',
  USER_MANAGEMENT = '/users-management',
  ADD_NEW_DRIVER = '/create-driver',
  ADD_NEW_FA = '/create-freight-agent',
  ADD_NEW_ID = '/create-independent-dispatcher',
  ADD_NEW_TC = '/create-truck-company',
  ADD_NEW_CUSTOMER = '/create-customer',
  EDIT_DRIVER = '/edit-driver',
  EDIT_TC = '/edit-truck-company',
  EDIT_FA = '/edit-freight-agent',
  EDIT_ID = '/edit-independent-dispatcher',
  EDIT_CUSTOMER = '/edit-customer',
  REPORTS_LIST = '/reports-list',
  REPORT_ACCIDENT = '/accident-report/:id',
  REPORT_DAMAGE = '/damage-reports/:id',
  TICKET_INCIDENT = '/violation-report/:id',
  ADD_NEW_LOAD = '/create-load',
  LOADS = '/loads',
  LOADS_MAP = '/loads/map',
  EDIT_LOAD = '/edit-load/:id',
  TRACKING = '/tracking/:id',
  INVOICING = '/invoicing',
}
