import { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/global';
import { getSpecificLoad } from '../../../../../store/specificLoad/specificLoad';
import { displayProtectedImage, displayImageSrc } from '../../../../../helpers';
import './Bols.scss';
import { manageFilesForCustomer } from '../../../../../store/load/load';
import { IconButton } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { fetchUploadFile } from '../../../../../store/uploadFile/uploadFile';
import DeleteOptionModal from './DeleteOptionModal';

export function Bols({ changebolsScan, bolsScan, loadId }: any) {
  const { load, loading } = useAppSelector(state => state.specificLoad);
  const [typeOpenMedia, setTypeOpenMedia] = useState<string>("");
  const [shippersData, setShippersData] = useState<any>([]);
  const [receiversData, setReceiversData] = useState<any>([]);
  const [dataForDelete, setDataForDelete] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loadId) dispatch(getSpecificLoad(loadId));
  }, []);

  useEffect(() => {
    if (load?.data?.resource?.receivers && load?.data?.resource?.receivers?.length > 0) {
      setReceiversData(load?.data?.resource?.receivers)
    }
    if (load?.data?.resource?.shippers && load?.data?.resource?.shippers?.length > 0) {
      setShippersData(load?.data?.resource?.shippers)
    }
  }, [load]);

  const handleClickOpen = () => {
    changebolsScan(true);
  };

  const handleClose = () => {
    changebolsScan(false);
    const root: HTMLElement | null = document.querySelector('html');
    root ? root.style.overflow = 'initial' : null;
    root ? root.style.paddingRight = '0px': null;
  };

  const skeletoon = (
    <div className="bols-scan__row">
      <div className="bols-scan__title">&nbsp;</div>
      <div className="bols-scan__column">
        <div className="bols-scan_item"></div>
        <div className="bols-scan_item"></div>
        <div className="bols-scan_item"></div>
        <div className="bols-scan_item"></div>
      </div>
    </div>
  );

  useEffect(() => {
    if (
      load &&
      load.data &&
      load.data.resource &&
      load.data.resource.receivers &&
      load.data.resource.receivers.length > 0
    ) {
      load.data.resource.receivers.forEach((slice: any) => {
        if (slice.bols_scans && slice.bols_scans.length > 0)  {
          slice.bols_scans.forEach((value: any) => {
            displayProtectedImage(value.id, value.thumbnail, token);
          });
        }
      });
    }

    if (
      load &&
      load.data &&
      load.data.resource &&
      load.data.resource.shippers &&
      load.data.resource.shippers.length > 0
    ) {
      load.data.resource.shippers.forEach((slice: any) => {
        if (slice.bols_scans && slice.bols_scans.length > 0) {
          slice.bols_scans.forEach((value: any) => {
            displayProtectedImage(value.id, value.thumbnail, token);
          });
        }
      });
    }
  }, [load]);

  useEffect(() => {
    shippersData.forEach((slice: any) => {
      if (slice.bols_scans && slice.bols_scans.length > 0) {
        slice.bols_scans.forEach((value: any) => {
          displayProtectedImage(value.id, value.thumbnail, token);
        });
      }
    });
  }, [shippersData])

  useEffect(() => {
    receiversData.forEach((slice: any) => {
      if (slice.bols_scans && slice.bols_scans.length > 0) {
        slice.bols_scans.forEach((value: any) => {
          displayProtectedImage(value.id, value.thumbnail, token);
        });
      }
    });
  }, [receiversData])

  const onAddNewFile = (customerId: number, fileType: string, e: any, customerType: string) => {
    if (e && e.target && e.target.files) {
      const fd = new FormData();
      fd.append('file', e.target.files[0]);
      dispatch(fetchUploadFile(fd)).then((res: any) => {
        if (res.type === 'uploadFile/fulfilled') {
            dispatch(manageFilesForCustomer({
              loadId: load.data.resource.id,
              customerId,
              fileType,
              action: 'create',
              file: res.payload.file.filepath,
            })).then((result) => {
              if (customerType === 'shipper') {
                setShippersData(shippersData.map((customer: any) => {
                  if (customer.id === customerId) {
                    return {
                      ...customer,
                      bols_scans: result.payload.resource.bols_scans,
                      freight_pictures: result.payload.resource.freight_pictures
                    }
                  } else {
                    return customer;
                  }
                }))
              } else if (customerType === 'receiver') {
                setReceiversData(receiversData.map((customer: any) => {
                  if (customer.id === customerId) {
                    return {
                      ...customer,
                      bols_scans: result.payload.resource.bols_scans,
                      freight_pictures: result.payload.resource.freight_pictures
                    }
                  } else {
                    return customer;
                  }
                }))
              }
            })
        }
      });
    }
  };

  const onHandleOpenDeleteModal = (customerId: number, fileType: string, customerType: string, fileId: number) => {
    setDataForDelete({
      customerId,
      fileType,
      id: fileId,
      customerType,
    });
    setDeleteModal(true);
  }

  const onDeleteFile = () => {
    dispatch(manageFilesForCustomer({
      loadId: load.data.resource.id,
      customerId: dataForDelete.customerId,
      fileType: dataForDelete.fileType,
      action: 'delete',
      id: dataForDelete.id,
    })).then((result) => {
      if (dataForDelete.customerType === 'shipper') {
        setShippersData(shippersData.map((customer: any) => {
          if (customer.id === dataForDelete.customerId) {
            return {
              ...customer,
              bols_scans: result.payload.resource.bols_scans,
              freight_pictures: result.payload.resource.freight_pictures
            }
          } else {
            return customer;
          }
        }))
        setDataForDelete(null);
        setDeleteModal(false);
      } else if (dataForDelete.customerType === 'receiver') {
        setReceiversData(receiversData.map((customer: any) => {
          if (customer.id === dataForDelete.customerId) {
            return {
              ...customer,
              bols_scans: result.payload.resource.bols_scans,
              freight_pictures: result.payload.resource.freight_pictures
            }
          } else {
            return customer;
          }
        }))
        setDataForDelete(null);
        setDeleteModal(false);
      }
    })
  }

  const token = localStorage.getItem('token');
  const rowReceivers =
  receiversData.length > 0
    ? receiversData.map((slice: any) => {
        let count = 0;
        if (slice.bols_scans && slice.bols_scans.length > 0) {
          count = count + slice.bols_scans.length;
        }

        return (
          <div
            key={slice.index}
            className={`data-pack__row ${count === 0 ? 'none' : ''}`}
          >
            <div className="data-pack__title">
              {slice.type
                ? slice.type === 'receiver'
                  ? 'Delivery'
                  : slice.type
                : ' - '}{' '}
              {slice.index ? slice.index : ' - '}
            </div>
            <div className="data-pack__column">
              {slice.bols_scans && slice.bols_scans.length > 0
                ? slice.bols_scans.map((value: any) => {
                    return (
                      <>
                      <div
                        key={value.id}
                        id={value.id}
                        className="data-pack_item"
                        onClick={e => setImage(value.file, value?.ext ? value.ext : "pdf")}
                      ><span onClick={() => onHandleOpenDeleteModal(slice.id, 'bols_scan', 'receiver', value.id)} className="deleteBtn">+</span></div>
                      </>
                    );
                  })
                : ''}
                <div
                    className="data-pack_item uploadFileBtn"
                >
                  <div>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                    <input type="file" onChange={(e) => onAddNewFile(slice.id, 'bols_scan', e, 'receiver')} id="file-input" hidden/>
                    <CameraAltOutlinedIcon />
                  </IconButton>
                  </div>
                </div>
            </div>
          </div>
        );
      })
    : skeletoon;

    const rowShippers =
    shippersData.length > 0
      ? shippersData.map((slice: any) => {
          let count = 0;
          if (slice.bols_scans && slice.bols_scans.length > 0) {
            count = count + slice.bols_scans.length;
          } else if (slice.freight_pictures && slice.freight_pictures.length > 0) {
            count = count + slice.freight_pictures.length;
          }

          return (
            <div
              key={slice.index}
              className={`data-pack__row ${count === 0 ? 'none' : ''}`}
            >
              <div className="data-pack__title">
                {slice.type ? (slice.type === 'shipper' ? 'Pickup' : slice.type) : ' - '}{' '}
                {slice.index ? slice.index : ' - '}
              </div>
              <div className="data-pack__column">
                {slice.bols_scans && slice.bols_scans.length > 0
                  ? slice.bols_scans.map((value: any) => {
                      return (
                        <div
                          key={value.id}
                          id={value.id}
                          className="data-pack_item"
                          onClick={e => setImage(value.file, value?.ext ? value.ext : "pdf")}
                        >
                          <span onClick={() => onHandleOpenDeleteModal(slice.id, 'bols_scan', 'shipper', value.id)} className="deleteBtn">+</span>
                        </div>
                      );
                    })
                  : ''}
                  <div className="data-pack_item uploadFileBtn">
                    <div>
                    <IconButton color="primary" aria-label="upload picture" component="label">
                      <input type="file" onChange={(e) => onAddNewFile(slice.id, 'bols_scan', e, 'shipper')} id="file-input" hidden/>
                      <CameraAltOutlinedIcon />
                    </IconButton>
                    </div>
                  </div>
              </div>
            </div>
          );
        })
      : skeletoon;

  const setImage = (file: string, type: string) => {
    setTypeOpenMedia(type === "pdf" ? type : "jpg");
    displayImageSrc('place-image', file, token);
    setSingleImage();
  };
  const refContainer = useRef<HTMLDivElement>(null);

  const setSingleImage = () => {
    if (refContainer.current !== null) {
      refContainer.current.style.zIndex = '100000';
      refContainer.current.style.opacity = '1';
      refContainer.current.style.pointerEvents = 'initial';
    }
  };

  const sdeleteSingleImage = () => {
    if (refContainer.current !== null) {
      refContainer.current.style.zIndex = '-10000';
      refContainer.current.style.opacity = '0';
      refContainer.current.style.pointerEvents = 'none';
      const imageElement: HTMLElement | null = document.getElementById('place-image');
      if (imageElement) {
        imageElement.setAttribute('src', '');
      }
    }
  };

  const closeSingleImage = (event: any) => {
    
    if (
      !event.target.classList.contains('bols-scan_item') &&
      !event.target.classList.contains('image-bols-scan')
    ) {
      sdeleteSingleImage();
    }
  };

  return (
    <div
      onClick={event => closeSingleImage(event)}
      className={`popup-bols-scan ${bolsScan ? '_active' : ''}`}
    >
      <div ref={refContainer} className="singleWrap">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
       {typeOpenMedia === "pdf" ? <iframe id="place-image" frameBorder="0" className="bols-scan">Your browser does not support frames</iframe>
       : <img id="place-image" className="image-bols-scan" />
        }
      </div>
      <div className="popup-bols-scan__wrap">
        <div onClick={handleClose} className="popup-bols-scan__close"></div>
        <div className="popup-bols-scan__title">bol scan</div>
        <div className="bols-scan__container">
          {loading ? (
            skeletoon
          ) : (
            <>
              {rowReceivers}
              {rowShippers}
            </>
          )}
        </div>
      </div>

      {deleteModal && (
                    <DeleteOptionModal
                      open={deleteModal}
                      setOpen={setDeleteModal}
                      onSubmit={onDeleteFile} /> )}
    </div>
  );
}
