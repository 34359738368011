import { FC, useState } from 'react';
import Moment from 'react-moment';
import { TableBody, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ROUTES } from '../../../../router/routes';
import './Table.scss';

interface ITable {
  list?: any;
  changeSortType?: any;
  setPopup?: any;
  setModalMap?: any;
}

const TableComponent: FC<ITable> = ({ list, changeSortType, setPopup, setModalMap }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const goEditLoad = (id: number) => navigate(`/edit-load/${id}`);
  const goReportsList = () => navigate(ROUTES.REPORTS_LIST);
  const [copiedPopup, setCopiedPopup] = useState<any>({
    opened: false,
    id: 0,
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#373535',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td, th': {
      border: 0,
    },
  }));

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-root.MuiTableRow-head': {
      borderTop: '1px solid rgb(255 255 255 / 15%)',
    },
  }));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    '&.MuiTableContainer-root': {
      borderRadius: '0px',
    },
  }));

  return (
    <div className="dashboardMain">
      <StyledTableContainer className="tableContainer">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableHeadRow>
              <StyledTableCell>
                Load
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('load_number')}
                />
              </StyledTableCell>
              <StyledTableCell align="right">Pickup</StyledTableCell>
              <StyledTableCell align="right">Delivery</StyledTableCell>
              <StyledTableCell align="right">Company Name</StyledTableCell>
              <StyledTableCell align="right">ETA</StyledTableCell>
              <StyledTableCell align="right">
                Stops
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('stops')}
                />{' '}
              </StyledTableCell>
              <StyledTableCell align="right">
                {' '}
                Weight{' '}
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('weight')}
                />
              </StyledTableCell>
              <StyledTableCell align="right">Equipment</StyledTableCell>
              <StyledTableCell align="right">
                {' '}
                Status{' '}
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType('status')}
                />
              </StyledTableCell>
              <StyledTableCell align="right">Incident</StyledTableCell>
              <StyledTableCell align="right">Share</StyledTableCell>
              <StyledTableCell align="right">Rate Con</StyledTableCell>
              <StyledTableCell align="right">BOL</StyledTableCell>
              <StyledTableCell align="right">Map</StyledTableCell>
              <StyledTableCell align="right">Data Pack</StyledTableCell>
            </StyledTableHeadRow>
          </TableHead>

          {/* tempolary solution for working with real data and static */}
          {list
            ? list &&
              list.data &&
              list.data.resource && (
                <TableBody>
                  {list.data.resource.data.map((row: any) => (
                    <StyledTableRow
                      key={`${row.id}`}
                      className={`${
                        row.status === 'in-progress'
                          ? 'inProgressRow'
                          : row.status === 'completed'
                          ? 'completedRow'
                          : 'pendingRow'
                      }`}
                    >
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className="loadsCell"
                        onClick={() => goEditLoad(row.id)}
                      >
                        {row.load_number}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="greyCell alignLeft">
                        {row.shippers[0]?.customer?.address?.address
                          ? row.shippers[0].customer.address.address
                          : ' - '}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="greyCell alignLeft">
                        {row.receivers[0]?.customer?.address?.address
                          ? row.receivers[0].customer.address.address
                          : ' - '}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="nowrapText">
                        {row.truck_company ? row.truck_company?.title : ' - '}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="alignLeft">
                        <span className="darkerGreyCell">
                          <Moment format="MM/DD/YYYY">{row.shippers.date}</Moment>
                        </span>
                        <br />
                        <span className="darkerGreyCell">
                          <Moment format="hh:mm A">{row.shippers.date}</Moment>
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.stops}</StyledTableCell>
                      <StyledTableCell align="right" className="nowrapText">
                        {row.weight ? row.weight : '-'}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="nowrapText">
                        {row.equipment_type_id ? row.equipment_type_id : '-'}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="nowrapText statusCell">
                        <div
                          className={`statusIcon ${
                            row.status === 'in-progress'
                              ? 'statucIconInProgress'
                              : row.status === 'completed'
                              ? 'statucIconCompleted'
                              : 'statucIconPending'
                          }`}
                        ></div>
                        {row.status === 'in-progress' ? 'In progress' : row.status}
                      </StyledTableCell>
                      {row.reports_count && row.reports_count > 0 ? (
                        <StyledTableCell
                          align="right"
                          className="reportCell"
                          onClick={() => goReportsList()}
                        >
                          {' '}
                          Reports{' '}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="right" className="nowrapText">
                          {' '}
                          -{' '}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="right" className="iconCell copiedCell">
                        {row.tracking_number ? <img
                          src={require('../../../../assets/images/icons/share-icon.png')}
                          alt="share"
                          width="25px"
                          height="25px"
                          onClick={() => {
                            const fullPath = window.location.href;
                            const fullPathForCopy = fullPath.replace(
                              location.pathname,
                              '',
                            );
                            navigator.clipboard
                              .writeText(
                                `${fullPathForCopy}/tracking/${row.tracking_number}`,
                              )
                              .then(() => {
                                setTimeout(() => {
                                  setCopiedPopup({
                                    opened: true,
                                    id: row.tracking_number,
                                  });
                                }, 500);
                              });
                            setTimeout(() => {
                              setCopiedPopup({
                                opened: false,
                                id: 0,
                              });
                            }, 3000);
                          }}
                        /> : ' - '}
                        {copiedPopup.opened && copiedPopup.id === row.tracking_number && (
                          <div className="copiedPopup">Copied!</div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="iconCell rateConCell">
                        {' '}
                        {row?.rate_con?.file ? <><img
                          src={require('../../../../assets/images/icons/pdf-icon.png')}
                          alt="share"
                          width="25px"
                          height="30px"
                        />
                        <a
                          href={row?.rate_con?.file}
                          target="_blank"
                          className="fullpathLink"
                          rel="noreferrer"
                        >
                          click
                        </a></> : ' - '}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="iconCell">
                        {' '}
                        {(row.bol_scans_count && row.bol_scans_count > 0) ? (
                          <img
                            src={require('../../../../assets/images/icons/scanner.png')}
                            alt="share"
                            width="30px"
                            height="30px"
                            onClick={() => setPopup(row.id, "bol-scan")}
                          />
                        ) : (
                          ' - '
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right" className="iconCell">
                        <img
                          src={require('../../../../assets/images/icons/map.png')}
                          alt="share"
                          width="30px"
                          height="30px"
                          onClick={() => setModalMap(row.id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right" className="iconCell">
                        {' '}
                        {(row.freight_pictures_count && row.freight_pictures_count > 0) ? (
                          <img
                            src={require('../../../../assets/images/icons/folder.png')}
                            alt="share"
                            width="35px"
                            height="35px"
                            onClick={() => setPopup(row.id, "data-pack")}
                          />
                        ) : (
                          ' - '
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )
            : null}
        </Table>
      </StyledTableContainer>
    </div>
  );
};

export default TableComponent;
