import React from 'react';
import {
  InputAdornment,
  OutlinedInput,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
// import Visibility from '@mui/icons-material/Visibility';
import { Visibility } from "@mui/icons-material"
import { makeStyles } from '@mui/styles';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { VisibilityOff } from "@mui/icons-material"
import './Input.scss';
import { Controller } from 'react-hook-form';

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  id?: string;
  tabIndex?: number;
  type?: string;
  showPassword?: boolean;
  className?: string;
  disabled?: boolean;
  value?: string;
  name?: string;
  mask?: any;
  handleClickShowPassword?: any;
  control?: any;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  watch?: any;
  formType?: string;
  withoutSpaces?: boolean;
  fromSignIn?: boolean;
}

const useStyles = makeStyles({
  outlinedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ffffff69',
    },
    '& .MuiOutlinedInput-input': {
      color: 'white',
      fontSize: 16,
    },
  },
  label: {
    '& .MuiInputLabel-root': {
      fontSize: '16px !important',
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
  },
});

export const Input: React.FC<IInput> = ({
  name,
  id,
  showPassword,
  handleClickShowPassword,
  type,
  className,
  control,
  isError,
  required,
  minLength,
  maxLength,
  watch,
  withoutSpaces,
  fromSignIn,
}) => {
  const classes = useStyles();

  const generateRules = () => {
    const rules: any = {};

    if (required) {
      rules.required = 'This field is required';
    }

    if (minLength) {
      rules.minLength = {
        value: minLength,
        message: `This field must be at least ${minLength}`,
      };
    }

    if (maxLength) {
      rules.maxLength = {
        value: maxLength,
        message: `This field must be less than ${maxLength}`,
      };
    }

    if (id === 'email' || id === 'userEmail') {
      rules.pattern = {
        value:
          /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
        message: 'Please enter a valid email address',
      };
    }

    if (id === 'password_confirm' || id === 'confirmPassword') {
      rules.validate = (val: string) => {
        if (watch('password') != val) {
          return 'Password and Confirm Password does not match.';
        }
      };
    }

    // if (type === 'password') {
    //   rules.pattern = {
    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     message:
    //       'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
    //   };
    // }

    return rules;
  };

  return (
    <Controller
      name={id ? id : ''}
      control={control}
      defaultValue=""
      rules={generateRules()}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          variant="outlined"
          className={`${className ? className : ''} formControl ${fromSignIn ? 'formControl_fromSignIn' : ''}`}
        >
          {!fromSignIn && <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ color: 'white' }}
            className={classes.label}
            error={isError}
          >
            {name}
          </InputLabel>}

          {fromSignIn && <div className="labelCustom">{name}</div>}
          <OutlinedInput
            autoComplete="on"
            error={isError}
            type={type === 'text' || showPassword ? 'text' : 'password'}
            value={withoutSpaces ? value.trim() : value}
            onChange={onChange}
            className={classes.outlinedInput}
            id={id ? id : ''}
            endAdornment={
              type === 'password' && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }
            label={name}
          />
          {isError && (
            <FormHelperText id="my-helper-text">{error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
