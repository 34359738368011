import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { UpdateFreightAgentModel } from '../../models/truckCompany/truckCompany.model';

export const getFreightAgentInfo = createAsyncThunk(
  'freightAgentInfo',
  async (id: number) => {
    const response = await api.get(`fa/${id}/get`);
    return response.data;
  },
);

export const updateFreightAgentInfo = createAsyncThunk(
  'updatingFreightAgentInfo',
  async (freightAgent: UpdateFreightAgentModel) => {
    const response = await api.post(`fa/${freightAgent.id}/update`, freightAgent);
    return response.data;
  },
);

export const deleteFreightAgent = createAsyncThunk(
  'deleteFreightAgent',
  async (id: {id : number}) => {
    const response = await api.post(
      `fa/${id.id}/delete`
    );
    return response.data;
  },
);

export const createFreightAgent = createAsyncThunk(
  'createFreightAgent',
  async (freightAgent: UpdateFreightAgentModel) => {
    const response = await api.post(`fa/create`, freightAgent);
    return response.data;
  },
);

export const getFreightAgentsList = createAsyncThunk('getFreightAgentsList', async (data: any) => {
  let requestString = `fa/list?page=${data.page}`;

  if (data.companyName) {
    requestString += `&company=${data.companyName}`;
  }

  if (data.city) {
    requestString += `&city=${data.city}`;
  }

  if (data.state) {
    requestString += `&state=${data.state}`;
  }
  
  const response = await api.get(requestString);
  return response.data;
});

interface FreightAgentState {
  carrierInfo: string | null;
  companyName: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  billingAddress: string | null;
  billingCity: string | null;
  billingState: string | null;
  billingZip: string | null;
  mcAuthority: string | null;
  taxId: string | null;
  dot: string | null;
  scacCode: string | null;
  isHazmat: string | null;
  officePhone: string | null;
  officeFax: string | null;
  cellPhone: string | null;
  errorMessage: string | null;
  logo: any;
  documents: [] | null;
  entityId: number | null;
  payableEmail: null;
  payablePhone: null;
  flNumber: string | null;
  email: any;
  userId: any;
}

const initialState: FreightAgentState = {
  carrierInfo: null,
  companyName: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  billingAddress: null,
  billingCity: null,
  billingState: null,
  billingZip: null,
  mcAuthority: null,
  taxId: null,
  dot: null,
  scacCode: null,
  isHazmat: null,
  officePhone: null,
  officeFax: null,
  cellPhone: null,
  errorMessage: null,
  logo: null,
  documents: [],
  entityId: null,
  payableEmail: null,
  payablePhone: null,
  flNumber: null,
  email: null,
  userId: null,
};

export const truckCompany = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getFreightAgentInfo.fulfilled, (state, action) => {
      state.carrierInfo = action?.payload?.resource?.info || null;
      state.companyName = action?.payload?.resource?.title || null;
      state.address = action?.payload?.resource?.address?.city || null;
      state.city = action?.payload?.resource?.address?.city || null;
      state.state = action?.payload?.resource?.address?.state?.code || null;
      state.zip = action.payload.resource.address.zip || null;
      state.billingAddress = action.payload.resource.billing_address.address || null;
      state.billingCity = action.payload.resource.billing_address.city || null;
      state.billingState = action.payload.resource.billing_address.state?.code || null;
      state.billingZip = action.payload.resource.billing_address.zip || null;
      state.mcAuthority = action.payload.resource.mc_authority || null;
      state.taxId = action.payload.resource.tax_id || null;
      state.dot = action.payload.resource.dot_id || null;
      state.scacCode = action.payload.resource.scac || null;
      state.isHazmat = action.payload.resource.is_hazmat || null;
      state.officePhone = action.payload.resource.address.phone || null;
      state.officeFax = action.payload.resource.address.fax || null;
      state.cellPhone = action.payload.resource.address.cell_phone || null;
      state.logo = action.payload.resource.logo?.file || null;
      state.documents = action.payload.resource.documents || null;
      state.entityId = action.payload.resource.entity.id || null;
      state.payableEmail = action.payload.resource.billing_address.email || null;
      state.payablePhone = action.payload.resource.billing_address.email || null;
      state.flNumber = action.payload.resource.entity.fl_number || null;
      state.email = action.payload.resource.user.email || null;
      state.userId = action.payload.resource.user.id || null;
    });
    builder.addCase(getFreightAgentInfo.rejected, (state, action) => {
      state.errorMessage = action.error.message || '';
    });

    builder.addCase(updateFreightAgentInfo.fulfilled, (state, action) => {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  },
});

export default truckCompany.reducer;
