import { FC, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "./Filters.scss";
import moment from "moment";

interface IFilter {
  companyList?: { id: number; value: string }[];
  // changeDateTo?: (newValue: Dayjs | null) => void;
  // changeDateFrom?: (newValue: Dayjs | null) => void;
  changeDateTo?: any;
  changeDateFrom?: any;
  changeCurrentCompany?: (event: SelectChangeEvent) => void;
  dateTo?: any;
  dateFrom?: any;
  currentCompany?: string | null;
  companyValue?: any;
  setCompanyValue?: any;
}

const Filters: FC<IFilter> = ({
  companyList,
  changeDateTo,
  changeDateFrom,
  changeCurrentCompany,
  dateTo,
  dateFrom,
  currentCompany,
  companyValue,
  setCompanyValue,
}) => {
  return (
    <div className="filtersContainer">
      {setCompanyValue && (
        <div className="filtersContainer__filter">
          <h3 className="helperInputText">Company Name</h3>

          <FormControl size="small" fullWidth>
            <input
              value={companyValue}
              onChange={(ev) => setCompanyValue(ev.target.value)}
              className="filterCompany"
            />
          </FormControl>
        </div>
      )}

      <div className="filtersContainer__filter -datePicker">
        <h3 className="helperInputText">Date Range: From</h3>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="Select date"
              inputFormat="MM/dd/yyyy"
              value={dateFrom}
              onChange={changeDateFrom}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </div>

      <div className="filtersContainer__filter -datePicker">
        <h3 className="helperInputText">Date Range: To</h3>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="Select date"
              inputFormat="MM/dd/yyyy"
              value={dateTo}
              onChange={changeDateTo}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </div>

      <img
        src={require("../../../../../assets/images/icons/dashboard-icon.png")}
        alt="Logo"
        width="36px"
        height="36px"
        style={{cursor: 'pointer'}}
        onClick={() => {
          changeDateTo(moment().format('YYYY-MM-DD'), 'reset');
          changeDateFrom(moment().format('YYYY-MM-DD'), 'reset');
          setCompanyValue('');
        }}
      />
    </div>
  );
};

export default Filters;
