import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { IconButton } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../../hooks/global";
import {
  getDriverInfo,
  updateDriver,
  deleteDriver,
} from "../../../../../store/driver/driver";
import { fetchUploadFile } from "../../../../../store/uploadFile/uploadFile";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import Loader from "../../../../common/Loader/Loader";
import { Button } from "../../../../controls";
import UploadFileButton from "../../../../controls/UploadFileButton/UploadFileButton";
import "./EditDriver.scss";
import General from "./parts/General";
import { getStringWithoutSymbols } from "../../../../../helpers";
import { deleteUser, getUserToken } from "../../../../../store/user/user";
import DeleteModalConfirmation from "../../../../controls/DeleteModalConfirmation/DeleteModalConfirmation";

const EditDriver: FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const userData = localStorage.getItem("user");

  const [generalOpened, setGeneralOpened] = useState(true);
  const [tcId, setTcId] = useState<any>(null);
  const [driverLicenseOpened, setDriverLicenseOpened] = useState(false);
  const [generalLoading, setGeneralLoading] = useState<boolean>(false);
  const [medCardOpened, setMedCardOpened] = useState(false);
  const [truckRegistrationOpened, setTruckRegistrationOpened] = useState(false);
  const [trailerRegistrationOpened, setTrailerRegistrationOpened] =
    useState(false);
  const [truckMaintenanceReportOpened, setTruckMaintenanceReportOpened] =
    useState(false);
  const [upcomingServiceNeedsOpened, setUpcomingServiceNeedsOpened] =
    useState(false);
  const [cargoInsuranceOpened, setCargoInsuranceOpened] = useState(false);
  const [taxTagsOpened, setTaxTagsOpened] = useState(false);
  const [gender, setGender] = useState(null);
  const [birth, setBirth] = useState(null);
  const [generalInfo, setGeneralInfo] = useState<any>(null);
  const [truckId, setTruckId] = useState<any>(null);
  const [trailerId, setTrailerId] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });

  const [driverLicenseA, setDriverLicenseA] = useState({
    alias: "driver_licence_a",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [driverLicenseB, setDriverLicenseB] = useState({
    alias: "driver_licence_b",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [medCard, setMedCard] = useState({
    alias: "med_card",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [truckRegistration, setTruckRegistration] = useState({
    alias: "truck_reg",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [trailerRegistration, setTrailerRegistration] = useState({
    alias: "trailer_reg",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [truckMaintenanceReport, setTruckMaintenanceReport] = useState({
    alias: "maintenance_report",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [upcomingServiceNeeds, setUpcomingServiceNeeds] = useState({
    alias: "upcoming_service",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [taxTags, setTaxTags] = useState({
    alias: "tax_tag",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [cargoInsurance, setCargoInsurance] = useState({
    alias: "cargo_insurance",
    file: null,
    title: "",
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const goToLoginAs = () => {
    dispatch(getUserToken({
      user_id: generalInfo.id,
    })).then((res: any) => {
      if (res.type === 'getUserToken/fulfilled') {
        const loginAsLink = document.getElementById('login-as-link') as HTMLAnchorElement | null;
        if (loginAsLink) {
          // loginAsLink.href = `https://freightlife.dnmiss.com/login-process-${res.payload.resource}`;
          // loginAsLink.href = `https://freightlife.dnmiss.com/login-process/${res.payload.resource}`;
          loginAsLink.href = `http://localhost:3001/login-process/?token=${res.payload.resource}`
          loginAsLink.click();
        }
      }
    })
  }

  useEffect(() => {
    if (state) {
      if (!isNaN(state.id) && state.type && state.driverId) {
        setGeneralLoading(true);
        setTcId({id: state?.ownerId, value: state?.companyName})

        dispatch(getDriverInfo({ id: state?.ownerId, type: 'tc', driverId: state?.driverId })).then((res) => {
          setTimeout(() => {
            setGeneralLoading(false);
          }, 1500);

          if (res.type === "getDriver/fulfilled") {
            setGeneralInfo(res.payload.resource);
          }
        });
      }
    }
  }, [state]);

  useEffect(() => {
    if (generalInfo) {
      setTruckId({
        id: generalInfo.profile?.truck_number_id,
        value: generalInfo.profile?.truck_number,
      });
      setTrailerId({
        id: generalInfo.profile?.trailer_number_id,
        value: generalInfo.profile?.trailer_number,
      });
      setBirth(generalInfo.profile.birth);
      setGender(generalInfo.profile.gender);

      if (generalInfo.profile.avatar) {
        setAvatar({
          ...avatar,
          file: generalInfo.profile.avatar.file,
        });
      }

      if (
        generalInfo.profile.documents &&
        generalInfo.profile.documents.length
      ) {
        generalInfo.profile.documents.map((doc: any) => {
          if (doc.alias === "med_card") {
            setMedCard({
              ...medCard,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === "driver_licence_a") {
            setDriverLicenseA({
              ...driverLicenseA,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === "driver_licence_b") {
            setDriverLicenseB({
              ...driverLicenseB,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === "truck_reg") {
            setTruckRegistration({
              ...truckRegistration,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === "trailer_reg") {
            setTrailerRegistration({
              ...trailerRegistration,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === "maintenance_report") {
            setTruckMaintenanceReport({
              ...truckMaintenanceReport,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === "upcoming_service") {
            setUpcomingServiceNeeds({
              ...upcomingServiceNeeds,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === "tax_tag") {
            setTaxTags({
              ...taxTags,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          } else if (doc.alias === "cargo_insurance") {
            setCargoInsurance({
              ...cargoInsurance,
              preview: doc.thumbnail,
              fullpath: doc.file,
            });
          }
        });
      }
    }
  }, [generalInfo]);

  const onSubmit = async (data: any) => {
    const id = localStorage.getItem("owner_id");

    const docs = [];

    if (driverLicenseA.file) {
      docs.push(driverLicenseA);
    }

    if (driverLicenseB.file) {
      docs.push(driverLicenseB);
    }

    if (medCard.file) {
      docs.push(medCard);
    }

    if (taxTags.file) {
      docs.push(taxTags);
    }

    if (cargoInsurance.file) {
      docs.push(cargoInsurance);
    }

    if (truckRegistration.file) {
      docs.push(truckRegistration);
    }

    if (trailerRegistration.file) {
      docs.push(trailerRegistration);
    }

    if (truckMaintenanceReport.file) {
      docs.push(truckMaintenanceReport);
    }

    if (upcomingServiceNeeds.file) {
      docs.push(upcomingServiceNeeds);
    }

    if (generalInfo.profile.documents && generalInfo.profile.documents.length) {
      generalInfo.profile.documents.map((doc: any) => {
        if (
          doc.alias === "driver_licence_a" &&
          doc.file &&
          !driverLicenseA.fullpath
        ) {
          docs.push({
            alias: "driver_licence_a",
            delete: 1,
          });
        } else if (
          doc.alias === "driver_licence_b" &&
          doc.file &&
          !driverLicenseB.fullpath
        ) {
          docs.push({
            alias: "driver_licence_b",
            delete: 1,
          });
        } else if (doc.alias === "mad_card" && doc.file && !medCard.fullpath) {
          docs.push({
            alias: "med_card",
            delete: 1,
          });
        } else if (
          doc.alias === "cargo_insurance" &&
          doc.file &&
          !cargoInsurance.fullpath
        ) {
          docs.push({
            alias: "cargo_insurance",
            delete: 1,
          });
        } else if (
          doc.alias === "truck_reg" &&
          doc.file &&
          !truckRegistration.fullpath
        ) {
          docs.push({
            alias: "truck_reg",
            delete: 1,
          });
        } else if (
          doc.alias === "trailer_reg" &&
          doc.file &&
          !trailerRegistration.fullpath
        ) {
          docs.push({
            alias: "trailer_reg",
            delete: 1,
          });
        } else if (
          doc.alias === "maintenance_report" &&
          doc.file &&
          !truckMaintenanceReport.fullpath
        ) {
          docs.push({
            alias: "maintenance_report",
            delete: 1,
          });
        } else if (
          doc.alias === "upcoming_service" &&
          doc.file &&
          !upcomingServiceNeeds.fullpath
        ) {
          docs.push({
            alias: "upcoming_service",
            delete: 1,
          });
        } else if (doc.alias === "tax_tag" && doc.file && !taxTags.fullpath) {
          docs.push({
            alias: "tax_tag",
            delete: 1,
          });
        }
      });
    }

    setSubmitLoading(true);
    const dataForEdit: any = {
      driverId: state.driverId,
      user: {
        email: data.email || generalInfo.email,
      },
      profile: {
        first_name: data.first_name || generalInfo.profile.first_name,
        last_name: data.last_name || generalInfo.profile.last_name,
        gender: gender,
        birth: birth,
        truck_number: truckId?.value,
        trailer_number: trailerId?.value,
        truck_number_id: truckId?.id,
        trailer_number_id: trailerId?.id,
      },
      address: {
        phone: getStringWithoutSymbols(data.phone) || generalInfo.address.phone,
        cell_phone:
          getStringWithoutSymbols(data.cell_phone) ||
          generalInfo.address.cell_phone,
      },
    };

    if (docs.length) {
      dataForEdit.documents = docs;
    }

    if (avatar.temp) {
      dataForEdit.avatar = {
        file: avatar.temp,
        delete: 0,
      };
    }

    dispatch(updateDriver(dataForEdit)).then((res) => {
      if (res.type === "updateDriver/fulfilled") {
        toast.success("Driver was successfully edited!");
        navigate("/users-management", {
          state: {
            tab: "drivers",
          },
        });
      } else {
        setSubmitLoading(false);
      }
    });
  };

  const deleteDriverGlobal = () => {
    if (userData) {
      const parsedData = JSON.parse(userData);
      setSubmitLoading(true);
      const id = localStorage.getItem("owner_id");
      dispatch(
        deleteDriver({
          id,
          driverId: state.driverId,
          type:
            parsedData.role === "tc-admin" || parsedData.role === "tc-user"
              ? "tc"
              : parsedData.role === "fa-admin" || parsedData.role === "fa-user"
              ? "fa"
              : parsedData.role === "id-admin" || parsedData.role === "id-user"
              ? "id"
              : null,
        })
      ).then((res) => {
        if (res.type === "deleteDriver/fulfilled") {
          return navigate("/users-management");
        } else {
          setSubmitLoading(false);
        }
      });
    }
  };

  return (
    <>
      {/* <Header /> */}

      <Loader loading={generalLoading} />

      <div className="createDriver mainContainer">
        <div className="createDriver__container">
          <div className="createDriver__body">
            <div className="createDriver__userForm">
              <div className="createDriver__header">
                <div className="createDriver__title">
                  <h1>Edit Driver</h1>
                </div>

                <Button
                  title="BACK"
                  Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                  onClick={() =>
                    navigate("/users-management", {
                      state: {
                        tab: "drivers",
                      },
                    })
                  }
                  className="mapBtn"
                  variant="outlined"
                />
              </div>

              <div className="createDriver__generalInfo">
                <div className="h2-title">
                  <h2 className="h2-title__text">General Information</h2>
                  <img
                    src={require("../../../../../assets/images/icons/expand-icon.png")}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setGeneralOpened(!generalOpened)}
                    className={`openBtn ${
                      !generalOpened ? "openBtnOpened" : ""
                    }`}
                  />
                </div>

                <General
                  generalOpened={generalOpened}
                  control={control}
                  truckId={truckId}
                  setTruckId={setTruckId}
                  trailerId={trailerId}
                  setTrailerId={setTrailerId}
                  setGender={setGender}
                  setBirth={setBirth}
                  birth={birth}
                  generalInfo={generalInfo}
                  gender={gender}
                  tcId={tcId}
                />

                <hr className="horizontalLine fullHr" />
              </div>
            </div>

            <div className="createDriver__contactPersons">
              <div className="h2-title">
                <h2 className="h2-title__text">Driver License</h2>

                <img
                  src={require("../../../../../assets/images/icons/expand-icon.png")}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setDriverLicenseOpened(!driverLicenseOpened)}
                  className={`openBtn ${
                    !driverLicenseOpened ? "openBtnOpened" : ""
                  }`}
                />
              </div>

              {driverLicenseOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setDriverLicenseA}
                    value={driverLicenseA}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Med Card</h2>
                <img
                  src={require("../../../../../assets/images/icons/expand-icon.png")}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setMedCardOpened(!medCardOpened)}
                  className={`openBtn ${!medCardOpened ? "openBtnOpened" : ""}`}
                />
              </div>

              {medCardOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setMedCard}
                    value={medCard}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Truck Registration</h2>
                <img
                  src={require("../../../../../assets/images/icons/expand-icon.png")}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() =>
                    setTruckRegistrationOpened(!truckRegistrationOpened)
                  }
                  className={`openBtn ${
                    !truckRegistrationOpened ? "openBtnOpened" : ""
                  }`}
                />
              </div>

              {truckRegistrationOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setTruckRegistration}
                    value={truckRegistration}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Trailer Registration</h2>
                <img
                  src={require("../../../../../assets/images/icons/expand-icon.png")}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() =>
                    setTrailerRegistrationOpened(!trailerRegistrationOpened)
                  }
                  className={`openBtn ${
                    !trailerRegistrationOpened ? "openBtnOpened" : ""
                  }`}
                />
              </div>

              {trailerRegistrationOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setTrailerRegistration}
                    value={trailerRegistration}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Tax Tags</h2>
                <img
                  src={require("../../../../../assets/images/icons/expand-icon.png")}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setTaxTagsOpened(!taxTagsOpened)}
                  className={`openBtn ${!taxTagsOpened ? "openBtnOpened" : ""}`}
                />
              </div>

              {taxTagsOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setTaxTags}
                    value={taxTags}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Cargo Insurance</h2>
                <img
                  src={require("../../../../../assets/images/icons/expand-icon.png")}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setCargoInsuranceOpened(!cargoInsuranceOpened)}
                  className={`openBtn ${
                    !cargoInsuranceOpened ? "openBtnOpened" : ""
                  }`}
                />
              </div>

              {cargoInsuranceOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setCargoInsurance}
                    value={cargoInsurance}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Truck Maintenance Report</h2>
                <img
                  src={require("../../../../../assets/images/icons/expand-icon.png")}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() =>
                    setTruckMaintenanceReportOpened(
                      !truckMaintenanceReportOpened
                    )
                  }
                  className={`openBtn ${
                    !truckMaintenanceReportOpened ? "openBtnOpened" : ""
                  }`}
                />
              </div>

              {truckMaintenanceReportOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setTruckMaintenanceReport}
                    value={truckMaintenanceReport}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Upcoming Service Needs</h2>
                <img
                  src={require("../../../../../assets/images/icons/expand-icon.png")}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() =>
                    setUpcomingServiceNeedsOpened(!upcomingServiceNeedsOpened)
                  }
                  className={`openBtn ${
                    !upcomingServiceNeedsOpened ? "openBtnOpened" : ""
                  }`}
                />
              </div>

              {upcomingServiceNeedsOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setUpcomingServiceNeeds}
                    value={upcomingServiceNeeds}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}
            </div>

            <div>
              <Button
                title="SAVE ALL"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                className="saveBtn"
                loading={submitLoading}
              />

              <Button
                title="delete"
                onClick={() => setDeleteModal(true)}
                variant="contained"
                className="cancelBtn deleteUserBtn"
                loading={submitLoading}
              />
            </div>
          </div>
          <DeleteModalConfirmation
            open={openDeleteModal}
            setOpen={setDeleteModal}
            deleteFunction={deleteDriverGlobal}
            loading={submitLoading}
          />
          <div className="createDriver__userInfo">
            <div className="addNewPhotoBtn">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    if (e && e.target && e.target.files) {
                      const fd = new FormData();
                      fd.append("file", e.target.files[0]);
                      dispatch(fetchUploadFile(fd)).then((res) => {
                        if (res.type === "uploadFile/fulfilled") {
                          setAvatar({
                            file: res.payload.file.fullpath,
                            temp: res.payload.file.filepath,
                          });
                        }
                      });
                    }
                  }}
                />
                <CameraAltOutlinedIcon />
              </IconButton>
            </div>
            <div className="userInfo__avatar">
              <img src={avatar.file} alt="" />
            </div>
            <div className="userInfo__name">
              {generalInfo
                ? `${generalInfo.profile.first_name} ${generalInfo.profile.last_name}`
                : ""}
            </div>
            <div className="userInfo__role">Driver</div>
            <div className="userInfo__email">
              {generalInfo ? generalInfo.email : ""}
            </div>
            {/* <Button title="LOGIN AS" variant="contained" onClick={() => goToLoginAs()} className="loginAsBtn"/>
            <a target="blank_" id="login-as-link"/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDriver;
